import { faPlus, faTags, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { DELETE_Voucher_API, GET_Voucher_API } from '../../../../APIConfig';
import Sidebar from '../../../../sections/my-account/organizer/sidebar/Sidebar';
import Alert from '../../../../sections/ui/Alert';
import Breadcrumbs from '../../../../sections/ui/Breadcrumbs';
import Card from '../../../../sections/ui/Card';
import VoucherForm from '../voucher/VoucherForm';
import VoucherItem from '../voucher/VoucherForm';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import CustomLoader from '../../../../sections/ui/CustomLoader';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import LabeledInput from '../../../../sections/ui/formfields/LabeledInput';
import {BACKEND_URL,CREATE_TEMPLATE_API,GET_TEMPLATE_API,EVENTS_FILTER_API} from '../../../../APIConfig';
import CustomFileInput2 from '../../../../sections/ui/formfields/CustomFIleInput2';
import ColorPicker  from '../../../../sections/ui/ColorPicker';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useAlert } from 'react-alert';
import CustomSelect from '../../../../sections/ui/formfields/CustomSelect';
import RadioInput2 from '../../../../sections/ui/formfields/RadioInput2';

const authDataString = localStorage.getItem('user');
const authData = JSON.parse(authDataString);
const headers = {
  Authorization: `Bearer ${authData && authData.access_token}`
};

const EventTemplateForm = ({ edit, showAlert, template}) => {
    const { t, i18n} = useTranslation('common');
    const initialFormData = {
        template_name:'Hello (name) thank you for your order',
        title: '',
        description:'<p>Dear customer, please find your tickets attached. </p> <p> [send_tickets_text] </p> <p>Have a nice day!</p> <p>Kind regards,</p><p>'+authData.user_detail.name+' </p>',
        banner_color:'',
        template_image: null,
        footer_image: null,
        added_events:[],
        status:-1
      };
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [alleventData, setAllEventData] = useState([]);
  const [reloadTemplate, setReloadTemplate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [IsNew, setIsNew] = useState(false);
  const [IsNewTwo, setIsNewTwo] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [templateNameTwo, setTemplateNameTwo] = useState('');
  const usalert = useAlert();
  const [currentPage, setCurrentPage] = useState(1);
  const [reload, setReload] = useState(false);

  const initialFilters = {
    sort_by: null,
    category_id: null,
    status: 2,
    expiredallow:"false",
    template:"true"
  };
  
  const [filters, setFilters] = useState(initialFilters);
  const {
    template_name,
    title,
    description,
    banner_color,
    added_events,
    status
  } = formData;

  
  useEffect(() => {
    const getEvents = async () => {
      try {
        setLoading(true);
        const headers = {
          Authorization: `Bearer ${authData && authData.access_token}`
        };
        const { data } = await axios.post(
          `${EVENTS_FILTER_API}?page=${currentPage}`,
          filters,
          {
            headers
          }
        );
     
       setAllEventData(data);
       let catArray = data.myevents.data.map(cat => ({
        label: cat.title,
        value: cat.id
      }));

      setEventData(catArray);
      setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    getEvents();
  }, [filters, currentPage, reload]);

  useEffect(() => {
    if (template !== null) {
      setFormData(template)
      setTemplateName(template.template_image);
      setIsNew(false);
      if(template.footer_image)
      {
      setTemplateNameTwo(template.footer_image);
      setIsNewTwo(false);
      }
    }
    else {
      setFormData(prevState => ({
        ...prevState,
        banner_color:'#31c5f4',
        status:0
      }))
    };
    return () => {
      setFormData(initialFormData);
    };
  }, [template]);


 /* useEffect(() => {
    const fetchTemplate = async () => {
      setLoading(true);
      setReloadTemplate(false);
      try {
      const { data } = await axios.post(GET_TEMPLATE_API, { template_type: 'event_based'},{ headers });
        if (data.success && data.data) {
          setFormData({
            ...data.data
          })
          if(data.data.template_image)
          {
          setTemplateName(data.data.template_image);
          setIsNew(false);
          }
          setBannerColor(data.data.banner_color)
        }
        else
        {
          setBannerColor('#F5A623')
        }
      } catch (error) {
        setBannerColor('#F5A623')
      }
      setLoading(false); 
    };
    fetchTemplate();
  }, [reloadTemplate, axios]);
*/
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("image", file);
            fetch(`https://www.myticketshop.be/backend/public/api/upload-image`, {
              method: "post",
              body: body
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: `https://www.myticketshop.be/backend/public/storage/${res.uploaded_file}`
                });
              })
              .catch((err) => {
              });
          });
        });
      }
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const formSubmit = async e => {
    e.preventDefault();
    const userData = localStorage.getItem('user');
    const userDataObj = JSON.parse(userData);
    let token = userDataObj !== null && userDataObj.access_token;
    if (!token) {
      return;
    }
    const data = {
      ...formData,
      template_type: 'event_based',
    };
    let exist = false;
    let updateaddedevents= [];
    data.added_events.forEach(vl=>{
      updateaddedevents.push(vl.value)
    })
    data.added_events=updateaddedevents;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    };
   const newFormData = new FormData();
   for (let key in data) {
     if (key === 'template_image') {
      if(data[key])
        exist=true;
     } else {
       newFormData.append(key, data[key]);
     }
   }
  var file = $('#images')[0].files[0];    
  if(file)
  {
  const filesize = (file.size / (1024*1024)).toFixed(2);
  if(filesize<=2)
  {
  newFormData.append(`template_image`, file);
  exist=true;
  }
  else
  {
  showAlert(t('maxlimit'),'error');
  usalert.error(t('maxlimit'));
  return;
     } 
  }
  var file = $('#images2')[0].files[0];    
  if(file)
  {
  const filesize = (file.size / (1024*1024)).toFixed(2);
  if(filesize<=2)
  {
  newFormData.append(`footer_image`, file);
  exist=true;
  }
  else
  {
  showAlert(t('maxlimit'),'error');
  usalert.error(t('maxlimit'));
  return;
     } 
  }
  if(data.added_events.length==0 || !exist)
  {
    showAlert(
      t('fillallfields'),
      'error'
    );
    return;
  }
 
    setLoading(true);
    try {
      let data = await axios.post(CREATE_TEMPLATE_API, newFormData, { headers });
      if(data.data.status)
      {
      showAlert(t('Created'), 'success');
      usalert.success(t('success'));
      }
      else
      {
        showAlert(
          data.data.message,
          'error'
        );
        usalert.error(data.data.message);
      }
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors !== undefined) {
        showAlert(t('PleaseCheckAllfields'), 'error');
      } else {
        showAlert(
          t('FailedCreation'),
          'error'
        );
      }
    }
    setLoading(false);
  }

  const onChange = e => {
  setFormData(prevState => ({
    ...prevState,
    [e.target.name]: e.target.value
  }));

 };

  return (
    <>
            <div className='col-lg-12'>
              <Card>
                <header className='section-heading mb-3'>
                  <h4 className='title-section'>
                    <FontAwesomeIcon icon={faTags} className='fa-w-14 mr-2' />
                    {t('DashboardPage.generaltemplates')}
                  </h4>
                </header>
                {message && (
                  <div
                    className={`alert alert-${error ? 'danger' : 'success'}`}>
                    {message}
                  </div>
                )}
                {loading && <CustomLoader style={{ textAlign: 'center' }} />}
                <div className='col-lg-12' style={{marginTop:'20px',padding:'0px'}}>
      
          <form onSubmit={formSubmit}>
            <div className='row'>
              <div className='col-12 col-md-12'>
                <LabeledInput
                  type='text'
                  label={t('DashboardPage.TemplateTitle')}
                  id='title'
                  name='title'
                  required
                  placeholder={t('DashboardPage.TemplateTitle')}
                  value={title}
                  onChange={onChange}
                />
              </div>
              <div className='col-12 col-md-12'>
                <LabeledInput
                  type='text'
                  label={t('DashboardPage.TemplateName')}
                  id='template_name'
                  name='template_name'
                  required
                  placeholder={t('DashboardPage.TemplateName')}
                  value={template_name}
                  onChange={onChange}
                />
              </div>
              <div className='col-12 col-md-12'>
                <CustomSelect
                  className='custom_select-div'
                  label={t('MyEventPage.SelectEvent')}
                  placeholder={t('MyEventPage.SelectEvent')}
                  name='added_events'
                  required="true"
                  isSearchable={false}
                  isMulti
                  value={added_events}
                  options={eventData}
                  handleChange={e => 
                  {
                    setFormData(state => ({
                      ...state,
                      added_events: e
                    }));
                  }}
                />
              </div>
              <div className='col-12 col-md-12 '>
              
              <label htmlFor='Description'>
                          {t('DashboardPage.TemplateDescription')}<span style={{ color: '#e95b35' }}>*</span>
                        
                        </label>
                        <small className='form-text text-muted mb-3' style={{marginTop:'0px'}}>
                             <FontAwesomeIcon
                               icon={faInfoCircle}
                               className='text-primary mr-1'
                              />
                            {t('DashboardPage.TemplateDescriptionWarning')}
                            </small>
                        <CKEditor
                          editor={ClassicEditor}
                          config={{
                            height: 300,
                            removePlugins: ['MediaEmbed'],
                            extraPlugins: [uploadPlugin]
                          }}
                          data={description ? description : ''}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setFormData(state => ({
                              ...state,
                              description: data
                            }));
                          }}
                        />
                </div>
                {
                  banner_color && 
                  (
                    <div className='col-12 col-md-12' style={{marginTop:'20px'}}>
                <label htmlFor='banner_color'>{t('DashboardPage.bannercolor')}<span style={{ color: '#e95b35' }}>*</span></label>
                  <ColorPicker name='banner_color' defaultColor={banner_color} onChange={(color)=>{setFormData(state => ({ ...state, banner_color: color }))}}/>
                </div>
                  )
                }
                {
                  status != -1 &&
                  <>
                  <div className='col-12 col-md-12 '>
                 <RadioInput2
                            label={t('CouponPage.Status')}
                            info=''
                            entries={[
                              {
                                label: t('MyEventPage.Enable'),
                                value: '1',
                                id: 'enable_status',
                                name: 'status',
                                selected: status == 1 ? true : false
                              },
                              {
                                label: t('MyEventPage.Disable'),
                                value: '0',
                                id: 'disable_status',
                                name: 'status',
                                selected: status == 0 ? true : false
                              }
                            ]}
                            onChange={onChange}
                          /> 
               </div>    
                  </>
                }
              
              <div className='col-12 col-md-12 padding-y' style={{padding:'0px',marginTop:'20px'}}>  
                <div className='col-12 col-md-12'>
                  {templateName && IsNew === false && (
                    <div style={{justifyContent:'center',display:'flex'}}>
                    <img src={BACKEND_URL + templateName} className="img-fluid"/>
                    </div>
                  )}
                  <CustomFileInput2
                    label={t('DashboardPage.TemplateImage')}
                    required="true"
                    id='images'
                    name='images'
                    info={t('DashboardPage.CustomTemplateWarningnew')}
                    handleFile={(e) => {                   
                      setTemplateName(e.target.files[0].name);
                      setIsNew(true);
                    }}
                  filename={
                    templateName
                      ? templateName
                      : ''
                  }
                  />
                  <small className='form-text text-muted mb-3' style={{marginTop:'-20px'}}>
                             <FontAwesomeIcon
                               icon={faInfoCircle}
                               className='text-primary mr-1'
                              />
                            {t('maxlimit')}
                            </small>
                </div>
             
              </div>
              <div className='col-12 col-md-12 padding-y' style={{padding:'0px',marginTop:'0px'}}>  
                <div className='col-12 col-md-12'>
                  {templateNameTwo && IsNewTwo === false && (
                    <div style={{justifyContent:'center',display:'flex'}}>
                    <img src={BACKEND_URL + templateNameTwo} className="img-fluid"/>
                    </div>
                  )}
                  <CustomFileInput2
                    label={t('DashboardPage.TemplateFooterImage')}
                    required="true"
                    id='images2'
                    name='images2'
                    info={t('DashboardPage.CustomTemplateWarningnew')}
                    handleFile={(e) => {
                      setTemplateNameTwo(e.target.files[0].name);
                      setIsNewTwo(true);
                    }}
                  filename={
                    templateNameTwo
                      ? templateNameTwo
                      : ''
                  }
                  />
                  <small className='form-text text-muted mb-3' style={{marginTop:'-20px'}}>
                             <FontAwesomeIcon
                               icon={faInfoCircle}
                               className='text-primary mr-1'
                              />
                            {t('maxlimit')}
                            </small>
                </div>
             
              </div>
            </div>
            <button style={{marginTop:'10px',marginBottom:'10px'}} className='btn btn-primary'>{
            template==null ? t('CouponPage.AddNewButton') : t('DashboardPage.UpdateButton')}</button>
            {loading && <CustomLoader style={{ textAlign: 'center' }} />}
          </form>
        </div>
              </Card>
            </div>
         
    </>
  );
};
export default EventTemplateForm;
