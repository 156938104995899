import { faEye, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import {useTranslation} from "react-i18next";
const MyBookingItem = ({ booking, deleteBooking, setShowPopup, setOrder, order }) => {
  const {t, i18n} = useTranslation('common');
  return (
   <Tr>
      <Td>
        {/* <span
          className='text-muted ml-2'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setShowPopup(true);
            setOrder(booking);
          }}
          title='View Booking Details'>
          <FontAwesomeIcon icon={faEye} color='#31c5f4' />
        </span> */}
        {order && order.status ? (
          <span
            className='text-muted ml-2'
            style={{ cursor:'pointer',textAlign:'center',display:'block'}}
            onClick={() => {
              window.open(`https://www.myticketshop.be/backend/public/api/download/ticket/${order.id}/${order.order_number}`)
            }}
            title='Download Ticket'>
            {/* <FontAwesomeIcon icon={faFile} color='#31c5f4' /> */}
            <p style={{color:'#31c5f4',textDecoration:'underline'}} className="customdownload">{t('download_ticket')}</p>
          </span>
        ) : null }

      </Td>
      <Td>{booking.order_number.toString()}</Td>
      <Td>
        {booking.event_slug ? (
          <Link to={`/event-details/${booking.event_slug}/${booking.event_id}`}>
            {booking.event_title}
          </Link>
        ) : (
          booking.event_title
        )}
      </Td>
      <Td>{booking.ticket_title}</Td>
      <Td>{booking.quantity}</Td>
      <Td>
        {moment(
          booking.event_start_date + ' ' + booking.event_start_time
        ).format('dddd DD MMM YYYY HH:mm')}
      </Td>
      <Td>
        {moment(booking.event_end_date + ' ' + booking.event_end_time).format(
          'dddd DD MMM YYYY HH:mm'
        )}
      </Td>
    </Tr>
  );
};

export default MyBookingItem;
