import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './custom.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_en from "./translations/en/common.json";
import common_de from "./translations/de/common.json";
import common_fr from "./translations/fr/common.json";

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'de',                              // language to use
  resources: {
    en: {
      common: common_en               // 'common' is our custom namespace
    },
    de: {
      common: common_de
    },
    fr: {
      common: common_fr
    }
  },
});
// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '5px',
  // you can also just use 'scale'
  transition: transitions.FADE
};
try{
var xhr = new XMLHttpRequest();
xhr.onreadystatechange = function() {
 if (xhr.readyState === XMLHttpRequest.DONE) {
    if (xhr.status === 200) {
     var response = JSON.parse(xhr.responseText);
     var all_script_url = response.user_scripts_link.replace(/\r/g, '').split("\n");
     var all_script_functions = response.user_scripts_func;
     for(var i=0; i<all_script_url.length; i++)
     {
        if(all_script_url)
         append_script(all_script_url)
     }
     setTimeout(function(){
      if(all_script_functions)
       append_script_functions(all_script_functions)
     },2000)
  } else {}
  }
};
xhr.open('GET', 'https://www.myticketshop.be/backend/public/api/script_settings', true);
xhr.send();

function append_script(data)
{
var script1 = document.createElement('script');
script1.src = data;
script1.async = true;
document.head.appendChild(script1);
}

function append_script_functions(data)
{
var script2 = document.createElement('script');
script2.innerHTML = data;
document.head.appendChild(script2);
}
}
catch(e){}
ReactDOM.hydrate(
  <Provider store={store}>
    <AlertProvider template={AlertTemplate} {...options}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </AlertProvider>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
