import React, { useState, useEffect } from 'react';
import Sidebar from '../../../sections/my-account/organizer/sidebar/Sidebar';
import Alert from '../../../sections/ui/Alert';
import Breadcrumbs from '../../../sections/ui/Breadcrumbs';
import Card from '../../../sections/ui/Card';
import CustomSelect from '../../../sections/ui/formfields/CustomSelect';
import LabeledInput from '../../../sections/ui/formfields/LabeledInput';
import DateInput from '../../../sections/ui/formfields/LabededDateField';
// import TimeInput from '../../../sections/ui/formfields/LabeledTimeField';
import CustomFileInput from '../../../sections/ui/formfields/CustomFileInput';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SortableList, { SortableItem } from "react-easy-sort";
import {
  // CATEGORY_API,
  GET_ORGANIZER_EVENT_BY_ID,
  CREATE_EVENT_API
  // BACKEND_URL
} from '../../../APIConfig';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import RadioInput2 from '../../../sections/ui/formfields/RadioInput2';
import moment from 'moment';
import CustomLoader from '../../../sections/ui/CustomLoader';
import { useHistory } from 'react-router';
import { useAlert } from 'react-alert';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { useSelector } from 'react-redux';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTrash } from '@fortawesome/free-solid-svg-icons';
// import { API_DOMAIN } from '../../../Config';
import EditEventGalleryImages from './EditEventGalleryImages';
import { Editor } from '@tinymce/tinymce-react';
import { TINYMCE_CONF, TINYMCE_KEY } from '../../../conf/tinymce';
import { useTranslation } from 'react-i18next';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ColorPicker  from '../../../sections/ui/ColorPicker';

const authDataString = localStorage.getItem('user');
const authData = JSON.parse(authDataString);
const headers = {
  Authorization: `Bearer ${authData && authData.access_token}`,
  Accept: 'application/json'
};

const initialFormData = {
  id: '',
  title: '',
  slug:'',
  excerpt: '',
  category_id: {
    label: '',
    value: ''
  },
  country_id: {
    label: '',
    value: ''
  },
  admin_comm_type: 0,
  org_comm_type: {
    label: '',
    value: ''
  },
  admin_commission: 0,
  organizer_commission: 0,
  description: '',
  faq: '',
  start_date: new Date(),
  end_date: new Date(),
  launch_date: new Date(),
  event_status:0,
  apply_fees:0,
  daily_sale:0,
  start_time: moment().format('HH:mm:ss'),
  end_time: moment().format('HH:mm:ss'),
  launch_time: moment().format('HH:mm:ss'),
  country_id: '',
  venue: '',
  address: '',
  city: '',
  state: '',
  zipcode: '',
  latitude: '',
  longitude: '',
  online_location: '',
  event_id: '',
  thumbnail: '',
  meta_title: '',
  meta_keywords: '',
  meta_description: '',
  tag_id: 0,
  slug: '',
  show_review: 0,
  video_link: '',
  poster: '',
  images: [],
  audience: [],
  ticket_footer: '',
  tickets_term_cond: '',
  show_ticket_limit: 1,
  show_ticket_limit_purchased: 1,
  password_protected:0,
  event_password:'',
  custom_field_enabled:0,
  custom_field_label:'',
  custom_field_required:0,
  allow_iframe:0,
  frame_text:'',
  frame_subtext:'',
  button_color:'#E19B63',
  url_color:'#E19B63',
  redirect_url:'',
  payment_methods:[]
};

export default function AddEvent(props) {
  const {t, i18n} = useTranslation('common');
  const [formData, setFormData] = useState(initialFormData);
  const [categoryData, setCategoryData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [publish, setPublish] = useState(0);
  const [status, setStatus] = useState(0);
  const [commTypeData, setcommTypeData] = useState([{ label: 'Fixed', value: 1 }, { label: 'Percentage', value: 2 }]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [StatusData, setStatusData] = useState([{ label: 'Published', value: 1 }, { label: 'Un-Published', value: 0 }]);

  const [posterImage, setPosterImage] = useState();
  const [imagesGallery, setImagesGallery] = useState([]);
  const history = useHistory();
  const alert = useAlert();
  const categoriesData = useSelector(state => state.StoreInfo.CategoryData);
  const countriesData = useSelector(state => state.countries.countries);
  const params = useParams();
  const [ExcerptLimitLeft, setExcerptLimitLeft] = useState(50);
  const [exxcerpt, setexxcerpt]= useState('');
  const [EditImageGallery, setEditImageGallery]= useState([]);

  useEffect(() => {
    setExcerptLimitLeft(50 - (exxcerpt && exxcerpt !== null ? exxcerpt.length : 0))
  }, [exxcerpt, ExcerptLimitLeft])

  // destrucure event data from formData
  const {
    id,
    title,
    slug,
    excerpt,
    category_id,
    country_id,
    description,
    faq,
    start_date,
    end_date,
    launch_date,
    event_status,
    apply_fees,
    daily_sale,
    start_time,
    launch_time,
    end_time,
    venue,
    address,
    city,
    state,
    zipcode,
    // latitude,
    // longitude,
    meta_title,
    meta_keywords,
    meta_description,
    show_review,
    video_link,
    images,
    poster,
    audience,
    org_comm_type,
    admin_comm_type,
    admin_commission,
    organizer_commission,
    ticket_footer,
    tickets_term_cond,
    show_ticket_limit,
    show_ticket_limit_purchased,
    password_protected,
    event_password,
    custom_field_enabled,
    custom_field_label,
    custom_field_required,
    allow_iframe,
    frame_text,
    frame_subtext,
    button_color,
    url_color,
    redirect_url,
    payment_methods
  } = formData;

  const onSortEnd = (oldIndex, newIndex) => {
    let newar = [...imagesGallery];
    var element = newar[oldIndex];
    newar.splice(oldIndex, 1);
    newar.splice(newIndex, 0, element);
    setImagesGallery([...newar]);
    
  };



  useEffect(() => {
    if (authDataString) {
      if (authData.user_detail.role_id !== 3) {
        history.push('/signin');
      }
    } else {
      history.push('/signin');
    }
  }, []);

  useEffect(() => {
    if (categoriesData && categoriesData.length > 0) {
      let catArray = categoriesData.map(cat => ({
        label: cat.name,
        value: cat.id
      }));
      setCategoryData(catArray);
    }
    if (countriesData && countriesData.length > 0) {
      let countriesArray = countriesData.map(country => ({
        label: country.country_name,
        value: country.id
      }));
      setCountryData(countriesArray);
    }
  }, [categoriesData, countriesData]);

  // get category list ( getting category list from the redux store )
  // useEffect(() => {
  //   const getCategories = async () => {
  //     try {
  //       const { data } = await axios({
  //         url: CATEGORY_API,
  //         method: 'GET'
  //       });
  //       if (data.success && data.data.length > 0) {
  //         let catArray = data.data.map(cat => ({
  //           label: cat.name,
  //           value: cat.id
  //         }));
  //         setCategoryData(catArray);
  //       }
  //     } catch (err) {
  //       // console.error(err);
  //     }
  //   };
  //   getCategories();
  // }, [axios]);

  // get the event data which needs to be edit
  useEffect(() => {
    const fetchEventData = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          GET_ORGANIZER_EVENT_BY_ID + params.slug,
          { headers }
        );
        setStatus(data.myevents.status)
        setPublish(data.myevents.publish)
        if (data.status && countriesData) {
          let selectedCountry = countriesData.find(
            country => country.id === data.myevents.country_id
          );
          setFormData({
            ...data.myevents,
            category_id: {
              label: data.myevents.category.name,
              value: data.myevents.category.id
            },
            button_color:data.myevents.button_color?data.myevents.button_color:'#E19B63',
            url_color:data.myevents.url_color?data.myevents.url_color:'#E19B63',
            admin_comm_type: data.myevents.admin_commission_type,
            org_comm_type: {
              label: data.myevents.organizer_commission_type == 1 ? 'Fixed' : data.myevents.organizer_commission_type == 2 ? 'Percentage' : '',
              value: data.myevents.organizer_commission_type
            },
            event_status:{
              label: data.myevents.event_status == 1 ? 'Published' : 'Un-Published',
              value: data.myevents.event_status
            },
            start_date:
              data.myevents.start_date === '0000-00-00'
                ? new Date()
                : data.myevents.start_date,
            launch_date:
              data.myevents.launch_date === '0000-00-00'
                ? new Date()
                : data.myevents.launch_date,
            end_date:
            data.myevents.end_date === '0000-00-00'
                ? new Date()
                : data.myevents.end_date,
            launch_time:
            data.myevents.launch_time === null
                ? '00:00:00'
                : data.myevents.launch_time,         
            images: JSON.parse(data.myevents.images),
            country_id: {
              label: selectedCountry
                ? selectedCountry.country_name
                : countriesData[0].country_name,
              value: selectedCountry ? selectedCountry.id : countriesData[0].id
            },
            // audience: data.myevents.audience ? data.myevents.audience.split(","): []
            audience: data.myevents.audience
              ? data.myevents.audience
                .split(',')
                .map(aud => ({ label: aud, value: aud }))
              : [],
              payment_methods: data.myevents.payment_methods
              ? data.myevents.payment_methods
                .split(',')
                .map(payment => ({ label: payment, value: payment }))
              : []
          });
          setexxcerpt(data.myevents.excerpt);
          setEditImageGallery(JSON.parse(data.myevents.images))
        }
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    };
    fetchEventData();
  }, [params.slug, axios, countriesData]);

  // handle the change of event fields
  const onChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  // form submit
  const submitHandler = async e => {
    e.preventDefault();
    
    if (!formData.description) {
      setErrorMessage(t('EventPage.DescriptionCannotEmpty'));
      return;
    }
    
    setErrorMessage('');
    let audienceString = '';
    if (audience.length > 0) {
      audienceString = audience.map(aud => aud.value).toString();
    }
    let paymentMethodsString = '';
    if (payment_methods.length > 0) {
      paymentMethodsString = payment_methods.map(payment => payment.value).toString();
    }
   if(formData["faq"]==null)
   {
    formData["faq"]="";
   }
   if(formData["description"]==null)
   {
    formData["description"]="";
   }
    const updatedFormData = {
      ...formData,
      event_id: id,
      category_id: category_id.value,
      country_id: country_id.value,
      org_comm_type: org_comm_type.value,
      start_date: moment(start_date).format('YYYY-MM-DD'),
      end_date: moment(end_date).format('YYYY-MM-DD'),
      launch_date: moment(launch_date).format('YYYY-MM-DD'),
      poster: posterImage ? posterImage : null,
      slug: title.replace(' ', '-'),
      audience: audienceString,
      payment_methods: paymentMethodsString,
      excerpt: exxcerpt,
      event_status:event_status.value,
      oldimages:JSON.stringify(EditImageGallery)
    };

    const newFormData = new FormData();

    for (let key in updatedFormData) {
      if (key === 'images' && imagesGallery.length > 0) {
        imagesGallery.forEach((image, index) =>
          newFormData.append(`images[${index}]`, image.file)
        );
      } else if (key === 'images' && imagesGallery.length <= 0) {
        newFormData.append(`images`, null);
      } else {
        newFormData.append(key, updatedFormData[key]);
      }
    }
  
    // Display the key/value pairs
    // for (var pair of newFormData.entries()) {
    //   console.table(pair[0], pair[1]);
    // }

    try {
      setSubmitLoading(true);
      const { data } = await axios.post(CREATE_EVENT_API, newFormData, {
        headers
      });
      alert.success(t('EventPage.EventUpdatedSuccessfully'));
      history.goBack();
      //history.push('/dashboard/organizer/my-events');

    } catch (error) {
      if (error.response && error.response.status === 422) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(t('EventPage.SomethingWentWrong'));
      }
    }
    setSubmitLoading(false);
  };

  const addImageGallery = e => {
    let gallery = [];
    for (let i = 0; i <= e.target.files.length; i++) {
      if (e.target.files[i]) {
        gallery[i] = {file:e.target.files[i],order:i,src: URL.createObjectURL(e.target.files[i])};
      }
    }
    setImagesGallery(gallery);
    // setFormData(prevState => ({
    //   ...prevState,
    //   images: imagesGallery
    // }));
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("image", file);
            // let headers = new Headers();
            // headers.append("Origin", "http://localhost:3000");
            fetch(`https://www.myticketshop.be/backend/public/api/upload-image`, {
              method: "post",
              body: body
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: `https://www.myticketshop.be/backend/public/storage/${res.uploaded_file}`
                });
              })
              .catch((err) => {

                // reject(err);
              });
          });
        });
      }
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <>
      <Breadcrumbs active='add-a-new-event' />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='add-a-new-event' />
            <div className='col-lg-9 mt-4 mt-lg-0'>
              <form onSubmit={submitHandler}>
                <Card>
                  <Alert type='warning'>
                  {t('EventPage.Mandatory')}
                  </Alert>
                  {errorMessage && <Alert type='error'>{errorMessage}</Alert>}
                  {loading && (
                    <CustomLoader
                      style={{
                        textAlign: 'center'
                      }}
                    />
                  )}
                  {!loading && (
                    <>
                      <CustomSelect
                        className='custom_select-div'
                        required={true}
                        label={t('EventPage.Category')}
                        options={categoryData}
                        name='category_id'
                        isClearable={false}
                        isSearchable={false}
                        value={category_id}
                        handleChange={e => {
                          setFormData(state => ({
                            ...state,
                            category_id: e
                          }));
                        }}
                      />

                      <div className='row'>
                        <div className='col-12 col-md-12'>
                          <LabeledInput
                            label={t('MyEventPage.EventName')}
                            required
                            id='title'
                            name='title'
                            value={title}
                            onChange={onChange}
                            info=''
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                        <LabeledInput
                            label={t('MyEventPage.Excerpt')}v
                            type='text'
                            id='excerpt'
                            name='excerpt'
                            value={exxcerpt}
                            onChange={(e)=> {
                              setexxcerpt(e.target.value)
                              setExcerptLimitLeft(50 - exxcerpt.length)
                              e.preventDefault();
                            }}
                            required
                            limitLeft={ExcerptLimitLeft}
                            limit={50}
                            showLimit={true}
                          />
                        </div>
                      </div>

                      <div className='form-group'>
                        <label htmlFor='Description'>
                        {t('MyEventPage.Description')}<span style={{ color: '#e95b35' }}>*</span>
                        </label>
                        {/* <Editor
                          apiKey={TINYMCE_KEY}
                          initialValue={description ? description : ''}
                          init={TINYMCE_CONF}
                          onChange={e => {
                            const data = e.target.getContent();
                            setFormData(state => ({
                              ...state,
                              description: data
                            }));
                          }}
                        /> */}
                        <CKEditor
                          editor={ClassicEditor}
                          config={{
                            extraPlugins: [uploadPlugin]
                          }}
                          //config={ckConfig}
                          data={description ? description : ''}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setFormData(state => ({
                              ...state,
                              description: data
                            }));
                          }}
                        />
                      </div>
                      {/* <div className='form-group'>
                        <label htmlFor='event_translations_en_description'>
                        {t('MyEventPage.WhyToAttendEvent')}
                        </label> */}
                        {/* <Editor
                          apiKey={TINYMCE_KEY}
                          initialValue={faq ? faq : ''}
                          init={TINYMCE_CONF}
                          onChange={e => {
                            const data = e.target.getContent();
                            setFormData(state => ({
                              ...state,
                              faq: data
                            }));
                          }}
                        /> */}
                        {/* <CKEditor
                          editor={ClassicEditor}
                          config={{
                            extraPlugins: [uploadPlugin]
                          }}
                          data={faq ? faq : ''}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setFormData(state => ({
                              ...state,
                              faq: data
                            }));
                          }}
                        /> */}
                      {/* </div> */}
                      <div className='row'>
                        <div className='col-12 col-md-12'>
                          <h5>
                          {t('MyEventPage.Timings')}
                          </h5>
                        </div>

                        <div className='col-12 col-md-6'>
                          <DateInput
                            label='Start Date'
                            type='text'
                            required
                            id='start_date'
                            setStartDate={date => {
                              setFormData(state => ({
                                ...state,
                                start_date: date
                              }));
                            }}
                            startDate={new Date(start_date)}
                            //minDate={moment().toDate()}
                          />
                        </div>

                        <div className='col-12 col-md-6'>
                          <label htmlFor='startTime'>
                          {t('MyEventPage.StartTime')}<span style={{ color: 'red' }}>*</span>
                          </label>
                          <TimePicker
                            id='startTime'
                            showSecond={false}
                            className='form-control'
                            defaultValue={moment(start_time, [
                              moment.ISO_8601,
                              'HH:mm'
                            ])}
                            allowEmpty={false}
                            onChange={e =>
                              setFormData(state => ({
                                ...state,
                                start_time: e.format('HH:mm:ss')
                              }))
                            }
                            use12Hours={false}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <DateInput
                            label={t('MyEventPage.EndDate')}
                            type='text'
                            required
                            id='end_date'
                            setStartDate={date => {
                              setFormData(state => ({
                                ...state,
                                end_date: date
                              }));
                            }}
                            startDate={new Date(end_date)}
                            minDate={start_date}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <label htmlFor='endTime'>
                          {t('MyEventPage.EndTime')}<span style={{ color: 'red' }}>*</span>
                          </label>
                          <TimePicker
                            id='endTime'
                            showSecond={false}
                            className='form-control'
                            defaultValue={moment(end_time, [
                              moment.ISO_8601,
                              'HH:mm'
                            ])}
                            allowEmpty={false}
                            onChange={e =>
                              setFormData(state => ({
                                ...state,
                                end_time: e.format('HH:mm:ss')
                              }))
                            }
                            use12Hours={false}
                          />
                        </div>

                        <div className='col-12 col-md-6'>
                          <DateInput
                            label={t('MyEventPage.LaunchDate')}
                            type='text'
                            required
                            id='launch_date'
                            setStartDate={date => {
                              setFormData(state => ({
                                ...state,
                                launch_date: date
                              }));
                            }}
                            startDate={new Date(launch_date)}
                            minDate={moment().toDate()}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <label htmlFor='launchTime'>
                          {t('MyEventPage.LaunchTime')}<span style={{ color: 'red' }}>*</span>
                          </label>
                          <TimePicker
                            id='launchTime'
                            showSecond={false}
                            className='form-control'
                            defaultValue={moment(launch_time, [
                              moment.ISO_8601,
                              'HH:mm'
                            ])}
                            allowEmpty={false}
                            onChange={e =>
                              setFormData(state => ({
                                ...state,
                                launch_time: e.format('HH:mm:ss')
                              }))
                            }
                            use12Hours={false}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <h5>
                          {t('MyEventPage.Location')}
                          </h5>
                        </div>

                        <div className='col-12 col-md-6'>
                          <CustomSelect
                            className='custom_select-div'
                            required={true}
                            label={t('MyEventPage.Country')}
                            options={countryData}
                            name='country'
                            isClearable={false}
                            value={country_id}
                            handleChange={e => {
                              setFormData(state => ({
                                ...state,
                                country_id: e
                              }));
                            }}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('MyEventPage.Venue')}
                            type='text'
                            required
                            id='venue'
                            name='venue'
                            value={venue}
                            onChange={onChange}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('MyEventPage.Address')}
                            type='text'
                            required
                            id='address'
                            name='address'
                            value={address}
                            onChange={onChange}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('MyEventPage.City')}
                            type='text'
                            required
                            id='city'
                            name='city'
                            value={city}
                            onChange={onChange}
                          />
                        </div>
                        {/* <div className='col-12 col-md-6'>
                          <LabeledInput
                            label='State'
                            type='text'
                            required
                            id='state'
                            name='state'
                            value={state}
                            onChange={onChange}
                          />
                        </div> */}
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('MyEventPage.Zip Code')}
                            type='text'
                            required
                            id='zipcode'
                            name='zipcode'
                            value={zipcode}
                            onChange={onChange}
                          />
                        </div>

                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('MyEventPage.eventpassword')}
                            type='text'
                           required={password_protected==1?true:false}
                            id='event_password'
                            name='event_password'
                            value={event_password}
                            onChange={onChange}
                          />
                        </div>

                        {/* <div className='col-12 col-md-6'>
                          <LabeledInput
                            label='Latitude'
                            type='text'
                            required
                            id='latitude'
                            name='latitude'
                            value={latitude}
                            onChange={onChange}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label='Longitude'
                            type='text'
                            required
                            id='longitude'
                            name='longitude'
                            value={longitude}
                            onChange={onChange}
                          />
                        </div> */}

                        <div className='col-12 col-md-12'>
                          <h5>
                          {t('MyEventPage.SEO')}
                          </h5>
                        </div>
                        <div className='col-12 col-md-12'>
                          <LabeledInput
                            label={t('MyEventPage.MetaTitle')}
                            type='text'
                            id='meta_title'
                            name='meta_title'
                            value={meta_title}
                            onChange={onChange}
                            required={true}
                          />
                          <LabeledInput
                            label={t('MyEventPage.MetaKeyword')}
                            type='text'
                            id='meta_keywords'
                            name='meta_keywords'
                            value={meta_keywords}
                            onChange={onChange}
                            required={true}
                          />
                          <LabeledInput
                            label={t('MyEventPage.MetaDescription')}
                            type='text'
                            id='meta_description'
                            name='meta_description'
                            value={meta_description}
                            onChange={onChange}
                            required={true}
                          />
                        </div>

                        <div className='col-12 col-md-12'>
                          <h5>
                          {t('MyEventPage.Fees')}
                          </h5>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label for="admin_comm_typee" className="required">{t('EventPage.AdminCommissionType')}</label>
                            <input type="text" id="admin_comm_typee" name="admin_comm_typee"
                              className="form-control undefined" autocomplete="off" label="Commission" readOnly={true} value={admin_comm_type == 1 ? 'Fixed' : admin_comm_type == 2 ? 'Percentage' : ''} />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label for="admin_commission" className="required">{t('MyEventPage.Commission')}</label>
                            <input type="text" id="admin_commission" name="admin_commission"
                              className="form-control undefined" autocomplete="off" label="Commission" readOnly={true} value={admin_commission} />
                          </div>
                        </div>
                        <div className='col-12 col-md-6'>
                          <CustomSelect
                            className='custom_select-div'
                            required={true}
                            label= {t('MyEventPage.OrgCommissionType')}
                            options={commTypeData}
                            name='org_comm_type'
                            isClearable={false}
                            value={org_comm_type}
                            
                            handleChange={e => {
                              setFormData(state => ({
                                ...state,
                                org_comm_type: e
                              }));
                            }}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('MyEventPage.Commission')}
                            type='text'
                            required
                            id='organizer_commission'
                            name='organizer_commission'
                            value={organizer_commission}
                            onChange={onChange}
                            
                          />
                        </div>
                      </div>
                      {status==1&&publish==1&&<>
                        <div className='row'>
                        <div className='col-12 col-md-12'>
                          <CustomSelect
                            className='custom_select-div'
                            required={true}
                            label={t('EventPage.IsPublished')}
                            options={StatusData}
                            name='event_status'
                            isClearable={false}
                            value={event_status}
                            handleChange={e => {
                              setFormData(state => ({
                                ...state,
                                event_status : e
                              }));
                            }}
                          />
                        </div>
                        </div>
                      </>}
                      
                      <div className='row'>
                        <div className='col-12 col-md-12'>
                          <RadioInput2
                            label={t('MyEventPage.EnableReviews')}
                            info={t('MyEventPage.EnableReviewsWarning')}
                            name='sho_review'
                            entries={[
                              {
                                label: t('MyEventPage.Enable'),
                                value: '1',
                                id: 'show_review`',
                                name: 'show_review',
                                selected: show_review === 1 ? true : false
                              },
                              {
                                label: t('MyEventPage.Disable'),
                                value: '0',
                                id: 'show_review2',
                                name: 'show_review',
                                selected: show_review === 0 ? true : false
                              }
                            ]}
                            onChange={onChange}
                          />
                        </div>
                      </div>

                     
                      <div className='row'>
                        <div className='col-12 col-md-12'>
                          <h5>
                          {t('MyEventPage.Media')}
                          </h5>
                        </div>
                        <div className='col-12 col-md-12'>
                          {images && images.length > 0 && (
                            <EditEventGalleryImages
                              images={images}
                              type='gallery'
                              eventId={id}
                              setEditImageGallery={setEditImageGallery}
                            />
                          )}
                          <CustomFileInput
                            label= {t('MyEventPage.ImagesGallery')}
                            id='images'
                            handleFile={addImageGallery}
                            info={t('MyEventPage.ImageGalleryWarning')}
                            filename={
                              imagesGallery.length > 0
                                ? imagesGallery.map(image => image.file.name).join()
                                : ''
                            }
                            multiple
                          />
                            <small className='form-text text-muted mb-3' style={{marginTop:'-20px'}}>
                             <FontAwesomeIcon
                               icon={faInfoCircle}
                               className='text-primary mr-1'
                              />
                            {t('maxlimit')}
                            </small>
                        </div>
                        <div className='col-12 col-md-12' style={{paddingLeft:4}}>
                        <SortableList
                            onSortEnd={onSortEnd}
                            className="listss"
                            draggedItemClassName="dragged"
                          >
                                {imagesGallery.map((item,index) => (
                               <SortableItem key={item.order}>
                                 <div className="item_img"
                                 style={{
                                  marginRight: 20,
                                  marginBottom: 20,
                                  width: '200px',
                                  borderRadius: 5,
                                  background:"url("+ item.src + ")",
                                  boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                                    }}>

                                 </div>
                                
                            </SortableItem>
                              ))}
                            </SortableList>

                        </div>
                        <div className='col-12 col-md-12'>
                          {poster && (
                            <EditEventGalleryImages
                              images={[poster]}
                              type='poster'
                              eventId={id}
                            />
                          )}

                          <CustomFileInput
                            label={t('MyEventPage.MainEventImage')}
                            id='poster'
                            handleFile={e => setPosterImage(e.target.files[0])}
                            info={t('MyEventPage.MainEventImageWarning')}
                            filename={posterImage ? posterImage.name : ''}
                          />
                            <small className='form-text text-muted mb-3' style={{marginTop:'-20px'}}>
                             <FontAwesomeIcon
                               icon={faInfoCircle}
                               className='text-primary mr-1'
                              />
                            {t('maxlimit')}
                            </small>
                        </div>
                        <div className='col-12 col-md-12'>
                          <CustomSelect
                            className='custom_select-div'
                            label={t('MyEventPage.Audience')}
                            options={[
                              { label: 'Adults', value: 'Adults' },
                              { label: 'Children', value: 'Children' },
                              { label: 'Family', value: 'Family' },
                              { label: 'Group', value: 'Group' },
                              { label: 'Youth', value: 'Youth' }
                            ]}
                            name='audience'
                            value={audience}
                            isMulti
                            handleChange={e => {
                              setFormData(state => ({
                                ...state,
                                audience: e
                              }));
                            }}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <CustomSelect
                            className='custom_select-div'
                            label={t('MyEventPage.PaymentMethods')}
                            options={[
                              { label: 'Cash', value: 'Cash' },
                              { label: 'Bancontact', value: 'Bancontact' },
                              { label: 'Visa / Master', value: 'Visa / Master' },
                            ]}
                            name='payment_methods'
                            value={payment_methods}
                            isMulti
                            handleChange={e => {
                              setFormData(state => ({
                                ...state,
                                payment_methods: e
                              }));
                            }}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <LabeledInput
                            label={t('MyEventPage.YoutubeVideoURL')}
                            type='url'
                            id='video_link'
                            name='video_link'
                            value={video_link == null ? '' : video_link == 'null' ? '' : video_link}
                            onChange={onChange}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <LabeledInput
                            label={t('MyEventPage.redirecturl')}
                            type='url'
                            id='redirect_url'
                            name='redirect_url'
                            value={redirect_url == null ? '' : redirect_url == 'null' ? '' : redirect_url}
                            onChange={onChange}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                <LabeledInput
                            label={t('shareurlupdate')}
                            type='text'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                          navigator.clipboard.writeText(`https://myticketshop.be/backend/public/share/${slug}/${id}`)
                          alert.success(t('copytoclipboard'));                          }}

                            value={`https://myticketshop.be/backend/public/share/${slug}/${id}`}
                            readOnly={true}
                          />
                </div>
                        
                      </div>
                      <div className='row'>
                        <div className='col-12 col-md-12'>
                          <h5>
                          {t('MyEventPage.TicketInfo')}
                          </h5>
                        </div>

                        <div className='col-12 col-md-12'>
                        <label htmlFor='Description'>
                        {t('MyEventPage.TermsAndCondition')}
                        </label>
                        <CKEditor
                          editor={ClassicEditor}
                          config={{
                            height: 300,
                            extraPlugins: [uploadPlugin]
                          }}
                          data={tickets_term_cond ? tickets_term_cond : ''}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setFormData(state => ({
                              ...state,
                              tickets_term_cond: data
                            }));
                          }}
                        />
                        </div>
                        <div className='col-12 col-md-12' style={{marginTop:'20px'}}>
                          <LabeledInput
                            label={t('MyEventPage.TicketFooter')}
                            type='text'
                            id='ticket_footer'
                            name='ticket_footer'
                            value={ticket_footer}
                            onChange={onChange}
                            //required={true}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                 <input
                  type='checkbox'
                  id='apply_fees'
                  name='apply_fees'
                  checked={apply_fees}
                  disabled
                  onChange={e =>
                    setFormData(state => ({
                      ...state,
                      apply_fees: e.target.checked ? 1 : 0
                    }))
                  }
                  placeholder='apply_fees'
                  style={{ marginRight: 5 }}
                />
                <label htmlFor='apply_fees'>{t('MyEventPage.apply_fees')}</label>
              </div>
              <div className='col-12 col-md-12'>
                 <input
                  type='checkbox'
                  id='daily_sale'
                  name='daily_sale'
                  checked={daily_sale}
                  onChange={e =>
                    setFormData(state => ({
                      ...state,
                      daily_sale: e.target.checked ? 1 : 0
                    }))
                  }
                  placeholder='daily_sale'
                  style={{ marginRight: 5 }}
                />
                <label htmlFor='daily_sale'>{t('MyEventPage.daily_sale')}</label>
              </div>
              <div className='col-12 col-md-12'>
                 <input
                  type='checkbox'
                  id='custom_field_enabled'
                  name='custom_field_enabled'
                  checked={custom_field_enabled}
                  onChange={e =>
                    setFormData(state => ({
                      ...state,
                      custom_field_enabled: e.target.checked ? 1 : 0
                    }))
                  }
                  placeholder='custom_field_enabled'
                  style={{ marginRight: 5 }}
                />
                <label htmlFor='custom_field_enabled'>{t('MyEventPage.custom_field')}</label>
              </div>
             
             {custom_field_enabled==1 && (
               <>
              
                <div className='col-12 col-md-12'>
                <LabeledInput
                            label={t('MyEventPage.CustomFieldLabel')}
                            type='text'
                            id='custom_field_label'
                            name='custom_field_label'
                            value={custom_field_label}
                            onChange={onChange}
                            required={true}
                          />
                </div>
                <div className='col-12 col-md-12'>
                          <RadioInput2
                            label={t('MyEventPage.CustomFieldRequired')}
                            info=''
                            entries={[
                              {
                                label:  t('MyEventPage.Enable'),
                                value: '1',
                                id: 'enable_customfieldrequired',
                                name: 'custom_field_required',
                                selected: custom_field_required === 1 ? true : false
                              },
                              {
                                label:t('MyEventPage.Disable'),
                                value: '0',
                                id: 'disable_customfieldrequired',
                                name: 'custom_field_required',
                                selected: custom_field_required === 0 ? true : false
                              }
                            ]}
                            onChange={onChange}
                          />
                        </div>
               
               
               </>
             )}
             <div className='col-12 col-md-12'>
                <RadioInput2
                  label={t('AllowIframe')}
                  info=''
                  entries={[
                    {
                      label:  t('MyEventPage.Enable'),
                      value: '1',
                      id: 'enable_allow_iframe',
                      name: 'allow_iframe',
                      selected: allow_iframe === 1 ? true : false
                    },
                    {
                      label:t('MyEventPage.Disable'),
                      value: '0',
                      id: 'disable_allow_iframe',
                      name: 'allow_iframe',
                      selected: allow_iframe === 0 ? true : false
                    }
                  ]}
                  onChange={onChange}
                />
              </div>
              {allow_iframe==1 && (
               <>
                <div className='col-12 col-md-12'>
                <LabeledInput
                            label={t('FrameText')}
                            type='text'
                            id='frame_text'
                            name='frame_text'
                            value={frame_text}
                            onChange={onChange}
                            required={true}
                          />
                </div>
                <div className='col-12 col-md-12'>
                <LabeledInput
                            label={t('FrameSubtext')}
                            type='text'
                            id='frame_subtext'
                            name='frame_subtext'
                            value={frame_subtext}
                            onChange={onChange}
                            required={true}
                          />
                </div>
                <div className='col-12 col-md-12'>
                <LabeledInput
                            label={t('generatedurl')}
                            type='text'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              let frame_code = `<iframe width="550" height="350" src="https://myticketshop.be/event-frame-details/${slug}/${id}" title="${title}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
                          navigator.clipboard.writeText(frame_code)
                          alert.success(t('copytoclipboard'));                          }}

                            value={`https://myticketshop.be/event-frame-details/${slug}/${id}`}
                            readOnly={true}
                          />
                </div>
                <div className='col-12 col-md-12'>
                <label htmlFor='button_color'>{t('buttoncolor')}</label>
                  <ColorPicker name='button_color' defaultColor={button_color} onChange={(color)=>{setFormData(state => ({ ...state, button_color: color }))}}/>
                </div>
                <div className='col-12 col-md-12' style={{marginTop:'15px'}}>
                <label htmlFor='url_color'>{t('urlcolor')}</label>
                <ColorPicker name='url_color' defaultColor={url_color} onChange={(color)=>{setFormData(state => ({ ...state, url_color: color }))}}/>
                </div>
               </>
             )}
              <div className='col-12 col-md-12' style={{marginTop:'10px'}}>
                 <input
                  type='checkbox'
                  id='password_protected'
                  name='password_protected'
                  checked={password_protected}
                  onChange={e =>
                    setFormData(state => ({
                      ...state,
                      password_protected: e.target.checked ? 1 : 0
                    }))
                  }
                  placeholder='password_protected'
                  style={{ marginRight: 5 }}
                />
                <label htmlFor='password_protected'>{t('MyEventPage.password_protected')}</label>
              </div>
                        <div className='col-12 col-md-12'>
                          <RadioInput2
                            label={t('MyEventPage.ShowTicketsLimit')}
                            info=''
                            entries={[
                              {
                                label:  t('MyEventPage.Enable'),
                                value: '1',
                                id: 'enable_ticketLimit',
                                name: 'show_ticket_limit',
                                selected: show_ticket_limit === 1 ? true : false
                              },
                              {
                                label: t('MyEventPage.Disable'),
                                value: '0',
                                id: 'disable_ticketLimit',
                                name: 'show_ticket_limit',
                                selected: show_ticket_limit === 0 ? true : false
                              }
                            ]}
                            onChange={onChange}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <RadioInput2
                            label={t('EventPage.ShowTicketsLimitPurchased')}
                            info=''
                            entries={[
                              {
                                label: t('MyEventPage.Enable'),
                                value: '1',
                                id: 'enable_ticketLimitPurchase',
                                name: 'show_ticket_limit_purchased',
                                selected: show_ticket_limit_purchased === 1 ? true : false
                              },
                              {
                                label: t('MyEventPage.Disable'),
                                value: '0',
                                id: 'disable_ticketLimitPurchase',
                                name: 'show_ticket_limit_purchased',
                                selected: show_ticket_limit_purchased === 0 ? true : false
                              }
                            ]}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {submitLoading ? (
                    <Loader
                      type='ThreeDots'
                      color='#31c5f4'
                      height={50}
                      width={50}
                      timeout={3000} //3 secs
                    />
                  ) : (
                    <button
                      type='submit'
                      name='_submit'
                      className='btn btn-primary btn'>
                      {t('MyEventPage.SaveButtonText')}
                    </button>
                  )}
                </Card>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
