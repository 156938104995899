import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function LabeledInput({textArea, ...props}) {
  const onChangeHandler = () => {};
  return (
    <>
      <div className='form-group'>
        {props.label ? (
          <label
            htmlFor={props.id}
            className={`${props.required ? 'required' : ''}`}>
            {props.label}
          </label>
        ) : (
          ''
        )}

        {props.info ? (
          <small className='form-text text-muted mb-3'>
            <FontAwesomeIcon
              icon={faInfoCircle}
              className='text-primary mr-1'
            />
            {props.info}
          </small>
        ) : (
          ''
        )}
        {props.noInput ? (
          ''
        ) : textArea ? (
          <>
          
          <textarea
            type={props.type}
            id={props.id}
            name={props.name}
            required={props.required}
            className={`form-control ${props.className}`}
            value={props.value}
            placeholder={props.placeholder}
            autoComplete='off'
            // onChange={(e) => onChangeHandler(e)}
            {...props}>
            {props.children}
          </textarea>
          
          </>
        ) : (
          <>
            {props.showLimit ?
              <input
              type={props.type}
              id={props.id}
              name={props.name}
              required={props.required}
              className={`form-control ${props.className}`}
              value={props.value}
              readOnly={props.readonly}
              placeholder={props.placeholder}
              autoComplete='off'
              maxLength={props.limit}
              // onChange={(e) => onChangeHandler(e)}
              {...props}
            />
            :
            <input
              type={props.type}
              id={props.id}
              name={props.name}
              required={props.required}
              className={`form-control ${props.className}`}
              value={props.value}
              placeholder={props.placeholder}
              autoComplete='off'
              // onChange={(e) => onChangeHandler(e)}
              {...props}
            />
            }
            {props.showLimit && (
              <p><span className="notranslate">{props.limitLeft} / {props.limit}</span> characters left</p>
            )}
            {props.extext && (<span style={{fontSize: '11px'}}>{props.extext}</span>)}
            
            {props.errorMessage && (
              <span style={{ color: '#e13d37' }}>{props.errorMessage}</span>
            )}
          </>
        )}
      </div>
    </>
  );
}
export default LabeledInput;
