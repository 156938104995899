import React, { useEffect, useState } from 'react';
import Sidebar from '../../../../sections/my-account/organizer/sidebar/Sidebar';
import Alert from '../../../../sections/ui/Alert';
import Breadcrumbs from '../../../../sections/ui/Breadcrumbs';
import Card from '../../../../sections/ui/Card';
import CustomSelect from '../../../../sections/ui/formfields/CustomSelect';
import LabeledInput from '../../../../sections/ui/formfields/LabeledInput';
import { EVENT_TICKETS_ALL,CREATE_EVENT_API,EVENTS_FILTER_API } from '../../../../APIConfig';
import axios from 'axios';
import moment from 'moment';
import CustomLoader from '../../../../sections/ui/CustomLoader';
import { useHistory } from 'react-router';
import { useAlert } from 'react-alert';
import 'rc-time-picker/assets/index.css';
import { useTranslation } from 'react-i18next';
import { API_DOMAIN } from '../../../../Config'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const initialFormData = {
  pdf_per_ticket:0
  };
  
  const authDataString = localStorage.getItem('user');
  const authData = JSON.parse(authDataString);
  const headers = {
    Authorization: `Bearer ${authData && authData.access_token}`,
    Accept: 'application/json'
  };
  
function SendFreeTicket(props) {
    const { t, i18n } = useTranslation('common');
    const [formData, setFormData] = useState(initialFormData);
    const [eventData, setEventData] = useState([]);
    const [allrows, setAllRows] = useState([{event_list:[],ticket_list:[],event_id:0,ticket_id:0,ticket_qty:0}]);
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [error, setError] = useState('');
    const history = useHistory();
    const alert = useAlert();
    const initialFilters = {
      sort_by: null,
      category_id: null,
      status: 2,
      expiredallow:"false"
    };
    const [filters, setFilters] = useState(initialFilters);
    const [reload, setReload] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const {
      customer_name,
      customer_email,
      email_body,
      pdf_per_ticket
    } = formData;
    const loggedInUser = localStorage.getItem('user')
    useEffect(() => {
      if (authDataString) {
        if (authData.user_detail.role_id !== 3) {
          history.push('/signin');
        }
      } else {
        history.push('/signin');
      }
    }, []);
  
    useEffect(() => {
      const getEvents = async () => {
        try {
          setLoading(true);
          const headers = {
            Authorization: `Bearer ${authData && authData.access_token}`
          };
          const { data } = await axios.post(
            `${EVENTS_FILTER_API}?page=${currentPage}`,
            filters,
            {
              headers
            }
          );
         
         let catArray = data.myevents.data.map(cat => ({
          label: cat.title,
          value: cat.id
        }));
        setEventData(catArray);
          setLoading(false);
        allrows[0].event_list=catArray;
        setAllRows([...allrows])
        } catch (err) {
          setLoading(false);
        }
      };
  
      getEvents();
    }, [filters, currentPage, reload]);

    const onChange = e => {
      setFormData(prevState => ({
        ...prevState,
        [e.target.name]: e.target.value
      }));
    };

    const addRow =function()
    { 
      allrows.push({event_list:[...eventData],ticket_list:[],event_id:0,ticket_id:0,ticket_qty:0})
      setAllRows([...allrows])
    }

    const removeRow = function(index)
    {
      allrows.splice(index,1);
      setAllRows([...allrows])
    }

    const getTickets = async (id,index) => {
      
      try {
        const { data } = await axios.post(
          EVENT_TICKETS_ALL,
          { event_id: id },
          { headers }
        );
      
    let catArray =data.tickets.map(cat => ({
      label: cat.title,
      value: cat.id
    }));
    
   allrows[index].ticket_list=catArray;
   allrows[index].ticket_id=0;
   setAllRows([...allrows])
      } catch (err) {
      }
    };

    const submitHandler = async e => {
      e.preventDefault();
      let allitems = [];
      allrows.forEach(vl=>{
        if(vl["event_id"].value&&vl["ticket_id"].value)
        {
        allitems.push(
          {
            "coupon_id":"",
            "event_id":vl["event_id"].value,
            "ticket_id":vl["ticket_id"].value,
            "ticket_qty":vl["ticket_qty"]
          }
        )
        }
      })
      /*allrows.map(ar=>{
        delete ar["event_list"];
        delete ar["ticket_list"];
        ar["coupon_id"]="";
        ar["event_id"]=ar["event_id"].value;
        ar["ticket_id"]=ar["ticket_id"].value;
        return ar;
       })*/
       
       if (!formData.customer_name || !formData.customer_email
       || allitems.length==0) {
        setError(t('fillallfields'));
        return;
      }
      const reqestData = {
        cart_detail: JSON.stringify(allitems),
        customer_name:formData.customer_name,
        customer_email:formData.customer_email,
        pdf_per_ticket:formData.pdf_per_ticket,
        email_body:formData.email_body?formData.email_body:'<p></p>'
      }
      const config = {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(loggedInUser).access_token}`
        }
      }
     
      try {
        setSubmitLoading(true);
        const { data } = await axios.post(
          `${API_DOMAIN}api/checkout_ticket`,
          reqestData,
          config
        )
        if (data.status) {
        alert.success(t('FreeTicketCreatedSuccessfully'));
        history.push('/dashboard/organizer/free-ticket');
        }
        else
        {
          setError(t('EventPage.SomethingWentWrong'));
        }
      } catch (error) {
        if (error.response && error.response.status === 422) {
          setError(error.response.data.message);
        } else {
          setError(t('EventPage.SomethingWentWrong'));
        }
      }
      setSubmitLoading(false);
      return;
    };
  
    function uploadAdapter(loader) {
      return {
        upload: () => {
          return new Promise((resolve, reject) => {
            const body = new FormData();
            loader.file.then((file) => {
              body.append("image", file);
              fetch(`https://www.myticketshop.be/backend/public/api/upload-image`, {
                method: "post",
                body: body
              })
                .then((res) => res.json())
                .then((res) => {
                  resolve({
                    default: `https://www.myticketshop.be/backend/public/storage/${res.uploaded_file}`
                  });
                })
                .catch((err) => {
                });
            });
          });
        }
      };
    }

    function uploadPlugin(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return uploadAdapter(loader);
      };
    }

    return (
      <>
        <Breadcrumbs active='free-tickets'  />
        <section className='section-content padding-y bg-white'>
          <div className='container'>
            <div className='row'>
              <Sidebar active='free-tickets'  />
              <div className='col-lg-9 mt-4 mt-lg-0'>
                <form onSubmit={submitHandler}>
                  <Card>
                    <Alert type='warning'>
                      {t('EventPage.Mandatory')}
                    </Alert>
                    {error && <Alert type='error'>{error}</Alert>}
                   
                    {loading && (
                      <CustomLoader
                        style={{
                          textAlign: 'center'
                        }}
                      />
                    )}
                    {!loading && (
                      <>
                      {
                         allrows.map((data,index) =>(
                            <>
                          <div className='row'>
                          <div className='col-12 col-md-4'>
                          <CustomSelect
                          className='custom_select-div'
                          required={true}
                          label={t('MyEventPage.SelectEvent')}
                          options={data.event_list}
                          name='event_id'
                          isClearable={false}
                          isSearchable={false}
                          value={data.event_id}
                          handleChange={e => {
                           getTickets(e.value,index)
                           allrows[index].event_id=e;
                          
                           setAllRows([...allrows])
                          }}
                        />
                        </div>
                        <div className='col-12 col-md-4'>
                          <CustomSelect
                          className='custom_select-div'
                          required={true}
                          label={t('MyEventPage.SelectTicket')}
                          options={data.ticket_list}
                          name='ticket_id'
                          isClearable={false}
                          isSearchable={false}
                          value={data.ticket_id}
                          handleChange={e => {
                          allrows[index].ticket_id=e;
                          setAllRows([...allrows])
                          }}
                        />
                        </div>
                        <div className='col-10 col-md-3'>
                            <LabeledInput
                              min="1"
                              max="50"
                              type='number'
                              label={t('TicketQuantity')}
                              id='ticket_qty'
                              name='ticket_qty'
                              value={data.ticket_qty}
                              onChange={e=>{
                                allrows[index].ticket_qty=e.target.value;
                                setAllRows([...allrows])
                              }}
                              required
                            />
                          
                        </div>
                        {
                          allrows.length==(index+1)?
                          <>
                          <div className='divdes col-2 col-md-1'>
                        <button className='btn rowbut'  onClick={() => {
                           addRow();
                          
                          }}>+</button>
                        </div>
                          </>
                          :
                          <>
                          <div className='divdes col-2 col-md-1'>
                        <button className='btn rowbut2' onClick={() => {
                           removeRow(index);
                          }}>-</button>
                        </div>
                        </>
                        }
                        
                       
                        </div>
                        
                        
                            </>
                             ))
                      }
                      
                         <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              label={t('CustomerName')}
                              id='customer_name'
                              name='customer_name'
                              value={customer_name}
                              onChange={onChange}
                              required
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              label={t('CustomerEmail')}
                              id='customer_email'
                              name='customer_email'
                              value={customer_email}
                              onChange={onChange}
                              type={'email'}
                              required
                            />
                          </div>
                        </div>
                  
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                          <CKEditor
                          editor={ClassicEditor}
                          config={{
                            height: 300,
                            removePlugins: ['MediaEmbed'],
                            extraPlugins: [uploadPlugin]
                          }}
                          data={email_body ? email_body : ''}
                          onChange={(event,editor) => {
                            const data = editor.getData();
                            setFormData(state => ({
                              ...state,
                              email_body: data
                            }));
                          }}
                        />
                       
                           </div>
                        </div>
                        <div className='row' style={{marginTop:'15px'}}>
                       <div className='col-12 col-md-12'>
                 <input
                  type='checkbox'
                  id='pdf_per_ticket'
                  name='pdf_per_ticket'
                  checked={pdf_per_ticket}
                  onChange={e =>
                    setFormData(state => ({
                      ...state,
                      pdf_per_ticket: e.target.checked ? 1 : 0
                    }))
                  }
                  placeholder='pdf_per_ticket'
                  style={{ marginRight: 5 }}
                />
                <label htmlFor='pdf_per_ticket'>{t('MyEventPage.pdf_per_ticket')}</label>
              </div>
                       </div>
                        {submitLoading ? (
                          <CustomLoader />
                        ) : (
                          <>
                            <button
                              type='submit'
                              name='_submit'
                              style={{marginTop:20}}
                              className='btn btn-primary btn'>
                              {t('SendButton')}
                            </button>
  
                            <button
                            style={{marginLeft:20,marginTop:20}}
                              onClick={()=>{
                                history.goBack();
                              }}
                              className='btn btn-primary btn'>
                              {t('CouponPage.CancelButton')}
                            </button>
                          </>
                        )}
                      </>
                    )}
                  </Card>
                </form>
              </div>
            </div>
          </div>
        </section>
      </>
      );
      
}

export default SendFreeTicket;