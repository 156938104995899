import React, { useEffect, useState } from 'react';
import Sidebar from '../../../sections/my-account/organizer/sidebar/Sidebar';
import Alert from '../../../sections/ui/Alert';
import Breadcrumbs from '../../../sections/ui/Breadcrumbs';
import Card from '../../../sections/ui/Card';
import CustomSelect from '../../../sections/ui/formfields/CustomSelect';
import LabeledInput from '../../../sections/ui/formfields/LabeledInput';
import DateInput from '../../../sections/ui/formfields/LabededDateField';
// import TimeInput from '../../../sections/ui/formfields/LabeledTimeField';
import CustomFileInput from '../../../sections/ui/formfields/CustomFileInput';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CATEGORY_API, CREATE_EVENT_API } from '../../../APIConfig';
import axios from 'axios';
import moment from 'moment';
import CustomLoader from '../../../sections/ui/CustomLoader';
import RadioInput2 from '../../../sections/ui/formfields/RadioInput2';
import { useHistory } from 'react-router';
import { useAlert } from 'react-alert';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { useSelector } from 'react-redux';
// import { CKEditor as CKEditor4 } from 'ckeditor4-react';
import { Editor } from '@tinymce/tinymce-react';
import { TINYMCE_CONF, TINYMCE_KEY } from '../../../conf/tinymce';
import { useTranslation } from 'react-i18next';
import SortableList, { SortableItem } from "react-easy-sort";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ColorPicker  from '../../../sections/ui/ColorPicker';
import { API_DOMAIN, IMAGE_BASE_URL } from '../../../Config';


const initialFormData = {
  title: '',
  excerpt: '',
  category_id: { label: '', value: 0 },
  country_id: {
    label: '',
    value: ''
  },
  admin_comm_type: {
    label: '',
    value: ''
  },
  org_comm_type: {
    label: '',
    value: ''
  },
  admin_commission: 0,
  organizer_commission: 0,
  description: '',
  faq: '',
  start_date: new Date(),
  end_date: new Date(),
  launch_date: new Date(),
  start_time: moment().format('HH:mm:ss'),
  end_time: moment().format('HH:mm:ss'),
  launch_time: moment().format('HH:mm:ss'),
  venue: '',
  address: '',
  city: '',
  state: '',
  zipcode: '',
  latitude: '',
  longitude: '',
  online_location: '',
  event_id: '',
  thumbnail: '',
  meta_title: '',
  meta_keywords: '',
  meta_description: '',
  tag_id: 0,
  slug: '',
  show_review: 1,
  show_ticket_limit: 1,
  show_ticket_limit_purchased: 1,
  poster: '',
  images: [],
  audience: [],
  tickets_term_cond: '',
  ticket_footer: '',
  apply_fees:1,
  daily_sale:0,
  password_protected:0,
  event_password:'',
  custom_field_enabled:0,
  custom_field_label:'',
  custom_field_required:0,
  allow_iframe:0,
  frame_text:'',
  frame_subtext:'',
  button_color:'#E19B63',
  url_color:'#E19B63',
  redirect_url:'',
  payment_methods:[],
};

const authDataString = localStorage.getItem('user');
const authData = JSON.parse(authDataString);
const headers = {
  Authorization: `Bearer ${authData && authData.access_token}`,
  Accept: 'application/json'
};

export default function AddEvent(props) {
  const { t, i18n } = useTranslation('common');
  const [formData, setFormData] = useState(initialFormData);
  const [categoryData, setCategoryData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [commTypeData, setcommTypeData] = useState([{ label: 'Fixed', value: 1 }, { label: 'Percentage', value: 2 }]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [admindata, setAdminData] = useState(false);

  const [error, setError] = useState('');
  const history = useHistory();
  const alert = useAlert();
  const categoriesData = useSelector(state => state.StoreInfo.CategoryData);
  const countriesData = useSelector(state => state.countries.countries);

  const [ExcerptLimitLeft, setExcerptLimitLeft] = useState(50);
  const [exxcerpt, setexxcerpt] = useState('');
 
  // destrucure event data from formData
  const {
    title,
    excerpt,
    category_id,
    org_comm_type,
    admin_comm_type,
    country_id,
    description,
    faq,
    start_date,
    end_date,
    launch_date,
    // start_time,
    // end_time,
    venue,
    address,
    city,
    state,
    zipcode,
    admin_commission,
    organizer_commission,
    // latitude,
    // longitude,
    meta_title,
    meta_keywords,
    meta_description,
    show_review,
    show_ticket_limit,
    show_ticket_limit_purchased,
    video_link,
    poster,
    images,
    audience,
    tickets_term_cond,
    ticket_footer,
    apply_fees,
    daily_sale,
    password_protected,
    event_password,
    custom_field_enabled,
    custom_field_label,
    custom_field_required,
    allow_iframe,
    allow_expire,
    frame_text,
    frame_subtext,
    button_color,
    url_color,
    redirect_url,
    payment_methods
  } = formData;

  useEffect(() => {
    if (authDataString) {
      if (authData.user_detail.role_id !== 3) {
        history.push('/signin');
      }
    } else {
      history.push('/signin');
    }
  }, []);

  const onSortEnd = (oldIndex, newIndex) => {
    let newar = [...images];
    var element = newar[oldIndex];
    newar.splice(oldIndex, 1);
    newar.splice(newIndex, 0, element);
     setFormData(prevState => ({
     ...prevState,
     images:[...newar] 
     }));
  };

  useEffect(() => {
    setExcerptLimitLeft(50 - exxcerpt.length);
  }, [exxcerpt, ExcerptLimitLeft])

  useEffect(()=>{
    const getEventsFees = async () => {
      try {
        setLoading(true);
        const headers = {
          Authorization: `Bearer ${authData && authData.access_token}`
        };
        const { data } = await axios.get(`${API_DOMAIN}api/myevents/event_fees`, {
          headers
        });
        setFormData(state => ({
          ...state,
          admin_comm_type: data.admin_commission_type,
          admin_commission: data.admin_commission
        }));
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    getEventsFees();
  },[admindata])

  useEffect(() => {
    if (categoriesData && categoriesData.length > 0) {
      let catArray = categoriesData.map(cat => ({
        label: cat.name,
        value: cat.id
      }));
      setCategoryData(catArray);
      setFormData(state => ({
        ...state,
        category_id: { label: catArray[0].label, value: catArray[0].value }
      }));
    }
    if (countriesData && countriesData.length > 0) {
      let countriesArray = countriesData.map(country => ({
        label: country.country_name,
        value: country.id
      }));
      setCountryData(countriesArray);
      setFormData(state => ({
        ...state,
        country_id: {
          label: 'Belgium',
          value: 21
        }
      }));
    }

  }, [categoriesData, countriesData]);

  // get category list ( getting category list from the redux store )
  // useEffect(() => {
  //   const getCategories = async () => {
  //     try {
  //       setLoading(true);
  //       const { data } = await axios({
  //         url: CATEGORY_API,
  //         method: 'GET'
  //       });
  //       if (data.success && data.data.length > 0) {
  //         let catArray = data.data.map(cat => ({
  //           label: cat.name,
  //           value: cat.id
  //         }));
  //         setCategoryData(catArray);
  //         setFormData(state => ({
  //           ...state,
  //           category_id: { label: catArray[0].label, value: catArray[0].value }
  //         }));
  //       }
  //     } catch (err) {
  //       console.error(err);
  //     }
  //     setLoading(false);
  //   };
  //   getCategories();
  // }, [axios]);

  // handle the change of event fields
  const onChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };
  const handleEditorChange1 = e => {
    const data = e.target.getContent();
    // setFormData(state => ({
    //   ...state,
    //   description: data
    // }));
  }
  const submitHandler = async e => {
    e.preventDefault();

    if (!formData.description) {
      setError(t('EventPage.DescriptionCannotEmpty'));
      return;
    }
    let audienceString = '';
    if (audience.length > 0) {
      audienceString = audience.map(aud => aud.value).toString();
    }
    let paymentMethodsString = '';
    if (payment_methods.length > 0) {
      paymentMethodsString = payment_methods.map(payment => payment.value).toString();
    }
    
    setError('');
    const updatedFormData = {
      ...formData,
      category_id: category_id.value,
      country_id: country_id.value,
      admin_comm_type: 0,
      org_comm_type: org_comm_type.value,
      start_date: moment(start_date).format('YYYY-MM-DD'),
      end_date: moment(end_date).format('YYYY-MM-DD'),
      launch_date: moment(launch_date).format('YYYY-MM-DD'),
      slug: title.replace(' ', '-'),
      audience: audienceString,
      payment_methods: paymentMethodsString,
      excerpt: exxcerpt,
      event_status:1
    };

    const newFormData = new FormData();
    for (let key in updatedFormData) {
      if (key === 'images' && images.length > 0) {
        images.forEach((image, index) =>
          newFormData.append(`images[${index}]`, image.file)
        );
      } else {
        newFormData.append(key, updatedFormData[key]);
      }
    }

    try {
      setSubmitLoading(true);
      const { data } = await axios.post(CREATE_EVENT_API, newFormData, {
        headers
      });
      alert.success(t('EventPage.EventCreatedSuccessfully'));
      history.goBack();
      //history.push('/dashboard/organizer/my-events');
    } catch (error) {
      if (error.response && error.response.status === 422) {
        setError(error.response.data.message);
      } else {
        setError(t('EventPage.SomethingWentWrong'));
      }
    }
    setSubmitLoading(false);

    return;
  };

  const DraftsubmitHandler = async e => {
    e.preventDefault();

    if (!formData.description) {
      setError(t('EventPage.DescriptionCannotEmpty'));
      return;
    }
    let audienceString = '';
    if (audience.length > 0) {
      audienceString = audience.map(aud => aud.value).toString();
    }
    let paymentMethodsString = '';
    if (payment_methods.length > 0) {
      paymentMethodsString = payment_methods.map(payment => payment.value).toString();
    }
    setError('');
    const updatedFormData = {
      ...formData,
      category_id: category_id.value,
      country_id: country_id.value,
      admin_comm_type: 0,
      org_comm_type: org_comm_type.value,
      start_date: moment(start_date).format('YYYY-MM-DD'),
      end_date: moment(end_date).format('YYYY-MM-DD'),
      launch_date: moment(launch_date).format('YYYY-MM-DD'),
      slug: title.replace(' ', '-'),
      audience: audienceString,
      payment_methods: paymentMethodsString,
      excerpt: exxcerpt
    };

    const newFormData = new FormData();
    for (let key in updatedFormData) {
      if (key === 'images' && images.length > 0) {
        images.forEach((image, index) =>
          newFormData.append(`images[${index}]`, image)
        );
      } else {
        newFormData.append(key, updatedFormData[key]);
      }
    }

    try {
      setSubmitLoading(true);
      const { data } = await axios.post(CREATE_EVENT_API, newFormData, {
        headers
      });
      if (data) {
        alert.success(t('EventPage.EventCreatedSuccessfully'));
        history.push(`/event-preview/${data.slug}/${data.event_id}`);

        const win = window.open(`/event-preview/${data.slug}/${data.event_id}`, "_blank");
        win.focus();
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        setError(error.response.data.message);
      } else {
        setError(t('EventPage.SomethingWentWrong'));
      }
    }
    setSubmitLoading(false);

    return;
  };

  const addImageGallery = e => {
    let imagesGallery = [];
    for (let i = 0; i <= e.target.files.length; i++) {
      if (e.target.files[i]) {
        imagesGallery[i] = {file:e.target.files[i],order:i,src: URL.createObjectURL(e.target.files[i])};
      }
    }

    setFormData(prevState => ({
      ...prevState,
      images: imagesGallery
    }));
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("image", file);
            // let headers = new Headers();
            // headers.append("Origin", "http://localhost:3000");
            fetch(`https://www.myticketshop.be/backend/public/api/upload-image`, {
              method: "post",
              body: body
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: `https://www.myticketshop.be/backend/public/storage/${res.uploaded_file}`
                });
              })
              .catch((err) => {
               
                // reject(err);
              });
          });
        });
      }
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <>
      <Breadcrumbs active='add-a-new-event' />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='add-a-new-event' />
            <div className='col-lg-9 mt-4 mt-lg-0'>
              <form onSubmit={submitHandler}>
                <Card>
                  <Alert type='warning'>
                    {t('EventPage.Mandatory')}
                  </Alert>
                  {error && <Alert type='error'>{error}</Alert>}
                  {/* {successMessage && (
                    <Alert type='success'>{successMessage}</Alert>
                  )} */}
                  {loading && (
                    <CustomLoader
                      style={{
                        textAlign: 'center'
                      }}
                    />
                  )}
                  {!loading && (
                    <>
                      <CustomSelect
                        className='custom_select-div'
                        required={true}
                        label={t('EventPage.Category')}
                        options={categoryData}
                        name='category_id'
                        isClearable={false}
                        isSearchable={false}
                        value={category_id}
                        handleChange={e => {
                          setFormData(state => ({
                            ...state,
                            category_id: e
                          }));
                        }}
                      />
                      <div className='row'>
                        <div className='col-12 col-md-12'>
                          <LabeledInput
                            label={t('MyEventPage.EventName')}
                            id='title'
                            name='title'
                            value={title}
                            onChange={onChange}
                            required
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <LabeledInput
                            label={t('MyEventPage.Excerpt')}
                            type='text'
                            id='excerpt'
                            name='excerpt'
                            value={exxcerpt}
                            onChange={(e) => {
                              setexxcerpt(e.target.value)
                              setExcerptLimitLeft(50 - exxcerpt.length)
                             
                              e.preventDefault();
                            }}
                            required
                            limitLeft={ExcerptLimitLeft}
                            limit={50}
                            showLimit={true}
                          />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label htmlFor='Description'>
                          {t('MyEventPage.Description')}<span style={{ color: '#e95b35' }}>*</span>
                        </label>
                        <CKEditor
                          editor={ClassicEditor}
                          config={{
                            height: 300,
                            removePlugins: ['MediaEmbed'],
                            extraPlugins: [uploadPlugin]
                          }}
                          data={description ? description : ''}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setFormData(state => ({
                              ...state,
                              description: data
                            }));
                          }}
                        />
                        {/* <CKEditor4
                          activeClass='editor'
                          data={description ? description : ''}
                          onChange={event => {
                            const editor = event.editor;
                            const data = editor.getData();
                            setFormData(state => ({
                              ...state,
                              description: data
                            }));
                          }}
                        /> */}
                      </div>
                      {/* <div className='form-group'>
                        <label htmlFor='event_translations_en_description'>
                          {t('MyEventPage.WhyToAttendEvent')}
                        </label> */}
                        {/* <Editor
                          apiKey={TINYMCE_KEY}
                          initialValue={faq ? faq : ''}
                          init={TINYMCE_CONF}
                          onChange={e => {
                            const data = e.target.getContent();
                            setFormData(state => ({
                              ...state,
                              faq: data
                            }));
                          }}
                        /> */}
                        {/* <CKEditor
                          editor={ClassicEditor}
                          config={{
                            removePlugins: ['MediaEmbed'],
                            extraPlugins: [uploadPlugin]
                          }}
                          data={faq ? faq : ''}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setFormData(state => ({
                              ...state,
                              faq: data
                            }));
                          }}
                        /> */}
                        {/* <CKEditor4
                          data={faq ? faq : ''}
                          onChange={event => {
                            const editor = event.editor;
                            const data = editor.getData();
                            setFormData(state => ({
                              ...state,
                              faq: data
                            }));
                          }}
                        /> */}
                      {/* </div> */}
                      <div className='row'>
                        <div className='col-12 col-md-12'>
                          <h5>
                            {t('MyEventPage.Timings')}
                          </h5>
                        </div>
                        <div className='col-12 col-md-6'>
                          <DateInput
                            label={t('MyEventPage.StartDate')}
                            type='text'
                            required
                            id='start_date'
                            setStartDate={date => {
                              setFormData(state => ({
                                ...state,
                                start_date: date
                              }));
                            }}
                            startDate={start_date}
                          //minDate={moment().toDate()}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <label htmlFor='startTime'>
                            {t('MyEventPage.StartTime')}<span style={{ color: 'red' }}>*</span>
                          </label>
                          <TimePicker
                            id='startTime'
                            use12Hours={false}
                            showSecond={false}
                            className='form-control'
                            defaultValue={moment()}
                            allowEmpty={false}
                            onChange={e =>
                              setFormData(state => ({
                                ...state,
                                start_time: e.format('HH:mm:ss')
                              }))
                            }
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <DateInput
                            label={t('MyEventPage.EndDate')}
                            type='text'
                            required
                            id='end_date'
                            setStartDate={date => {
                              setFormData(state => ({
                                ...state,
                                end_date: date
                              }));
                            }}
                            startDate={end_date}
                            minDate={start_date}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <label htmlFor='endTime'>
                            {t('MyEventPage.EndTime')}<span style={{ color: 'red' }}>*</span>
                          </label>
                          <TimePicker
                            id='endTime'
                            showSecond={false}
                            className='form-control'
                            defaultValue={moment()}
                            allowEmpty={false}
                            onChange={e =>
                              setFormData(state => ({
                                ...state,
                                end_time: e.format('HH:mm:ss')
                              }))
                            }
                            use12Hours={false}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <DateInput
                            label={t('MyEventPage.LaunchDate')}
                            type='text'
                            required
                            id='launch_date'
                            setStartDate={date => {
                              setFormData(state => ({
                                ...state,
                                launch_date: date
                              }));
                            }}
                            startDate={new Date(launch_date)}
                            minDate={moment().toDate()}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <label htmlFor='launchTime'>
                            {t('MyEventPage.LaunchTime')}<span style={{ color: 'red' }}>*</span>
                          </label>
                          <TimePicker
                            id='launchTime'
                            showSecond={false}
                            className='form-control'
                            defaultValue={moment()}
                            allowEmpty={false}
                            onChange={e =>
                              setFormData(state => ({
                                ...state,
                                launch_time: e.format('HH:mm:ss')
                              }))
                            }
                            use12Hours={false}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <h5>
                            {t('MyEventPage.Location')}
                          </h5>
                        </div>
                        {/* <div className='col-12 col-md-6'>
                          <LabeledInput
                            label='Venue'
                            type='text'
                            required
                            id='venue'
                            name='venue'
                            value={venue}
                            onChange={onChange}
                          />
                        </div> */}
                        <div className='col-12 col-md-6'>
                          <CustomSelect
                            className='custom_select-div'
                            required={true}
                            label={t('MyEventPage.Country')}
                            options={countryData}
                            name='country'
                            isClearable={false}
                            value={country_id}
                            handleChange={e => {
                              setFormData(state => ({
                                ...state,
                                country_id: e
                              }));
                            }}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('MyEventPage.Venue')}
                            type='text'
                            required
                            id='venue'
                            name='venue'
                            value={venue}
                            onChange={onChange}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('MyEventPage.Address')}
                            type='text'
                            required
                            id='address'
                            name='address'
                            value={address}
                            onChange={onChange}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('MyEventPage.City')}
                            type='text'
                            required
                            id='city'
                            name='city'
                            value={city}
                            onChange={onChange}
                          />
                        </div>
                        {/* <div className='col-12 col-md-6'>
                          <LabeledInput
                            label='State'
                            type='text'
                            id='state'
                            name='state'
                            value={state}
                            onChange={onChange}
                          />
                        </div> */}
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('MyEventPage.Zip Code')}
                            type='text'
                            required
                            id='zipcode'
                            name='zipcode'
                            value={zipcode}
                            onChange={onChange}
                          />
                        </div>

                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('MyEventPage.eventpassword')}
                            type='text'
                            required={password_protected==1?true:false}
                            id='event_password'
                            name='event_password'
                            value={event_password}
                            onChange={onChange}
                          />
                        </div>

                        {/* <div className='col-12 col-md-6'>
                          <LabeledInput
                            label='Latitude'
                            type='text'
                            required
                            id='latitude'
                            name='latitude'
                            value={latitude}
                            onChange={onChange}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label='Longitude'
                            type='text'
                            required
                            id='longitude'
                            name='longitude'
                            value={longitude}
                            onChange={onChange}
                          />
                        </div> */}
                        <div className='col-12 col-md-12'>
                          <h5>
                            {t('MyEventPage.SEO')}
                          </h5>
                        </div>

                        <div className='col-12 col-md-12'>
                          <LabeledInput
                            label={t('MyEventPage.MetaTitle')}
                            type='text'
                            id='meta_title'
                            name='meta_title'
                            value={meta_title}
                            onChange={onChange}
                            required={true}
                          />
                          <LabeledInput
                            label={t('MyEventPage.MetaKeyword')}
                            type='text'
                            id='meta_keywords'
                            name='meta_keywords'
                            value={meta_keywords}
                            onChange={onChange}
                            required={true}
                          />
                          <LabeledInput
                            label={t('MyEventPage.MetaDescription')}
                            type='text'
                            id='meta_description'
                            name='meta_description'
                            value={meta_description}
                            onChange={onChange}
                            required={true}
                          />
                        </div>

                        <div className='col-12 col-md-12'>
                          <h5>
                            {t('MyEventPage.Fees')}
                          </h5>
                        </div>
                        {/* <div className='col-12 col-md-6'>
                          <CustomSelect
                            className='custom_select-div'
                            required={true}
                            label='Admin Commission Type'
                            options={commTypeData}
                            name='admin_comm_type'
                            isClearable={false}
                            value={admin_comm_type}
                            disabled
                            handleChange={e => {
                              setFormData(state => ({
                                ...state,
                                admin_comm_type: e
                              }));
                            }}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label='Commission'
                            type='text'
                            required
                            id='admin_commission'
                            name='admin_commission'
                            value={admin_commission}
                            onChange={onChange}
                            required
                          />
                        </div> */}
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label for="admin_comm_typee" className="required">{t('EventPage.AdminCommissionType')}</label>
                            <input type="text" id="admin_comm_typee" name="admin_comm_typee"
                              className="form-control undefined" autocomplete="off" label="Commission" readOnly={true} value={admin_comm_type == 1 ? 'Fixed' : admin_comm_type == 2 ? 'Percentage' : ''} />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label for="admin_commission" className="required">{t('MyEventPage.Commission')}</label>
                            <input type="text" id="admin_commission" name="admin_commission"
                              className="form-control undefined" autocomplete="off" label="Commission" readOnly={true} value={admin_commission} />
                          </div>
                        </div>
                        <div className='col-12 col-md-6'>
                          <CustomSelect
                            className='custom_select-div'
                            required={true}
                            label={t('MyEventPage.OrgCommissionType')}
                            options={commTypeData}
                            name='org_comm_type'
                            isClearable={false}
                            value={org_comm_type}
                            handleChange={e => {
                              setFormData(state => ({
                                ...state,
                                org_comm_type: e
                              }));
                            }}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('MyEventPage.Commission')}
                            type='text'
                            required
                            id='organizer_commission'
                            name='organizer_commission'
                            value={organizer_commission}
                            onChange={onChange}
                          />
                          
                        </div>

                      </div>

                      <div className='row'>
                        <div className='col-12 col-md-12'>
                          <RadioInput2
                            label={t('MyEventPage.EnableReviews')}
                            info={t('MyEventPage.EnableReviewsWarning')}
                            entries={[
                              {
                                label: t('MyEventPage.Enable'),
                                value: '1',
                                id: 'enable_reviews',
                                name: 'enable_reviews',
                                selected: show_review === 1 ? true : false
                              },
                              {
                                label: t('MyEventPage.Disable'),
                                value: '0',
                                id: 'disable_reviews',
                                name: 'enable_reviews',
                                selected: show_review === 0 ? true : false
                              }
                            ]}
                            onChange={onChange}
                          />
                        </div>

                      </div>

                      <div className='row'>
                        <div className='col-12 col-md-12'>
                          <h5>
                            {t('MyEventPage.Media')}
                          </h5>
                        </div>
                        <div className='col-12 col-md-12'>
                          <CustomFileInput
                            label={t('MyEventPage.ImagesGallery')}
                            id='images'
                            handleFile={addImageGallery}
                            info={t('MyEventPage.ImageGalleryWarning')}
                            filename={
                              images.length > 0
                                ? images.map(image => image.file.name).join()
                                : ''
                            }
                            multiple
                          />
                           <small className='form-text text-muted mb-3' style={{marginTop:'-20px'}}>
                             <FontAwesomeIcon
                               icon={faInfoCircle}
                               className='text-primary mr-1'
                              />
                            {t('maxlimit')}
                            </small>
                        </div>
                        <div className='col-12 col-md-12' style={{paddingLeft:4}}>
                        <SortableList
                            onSortEnd={onSortEnd}
                            className="listss"
                            draggedItemClassName="dragged"
                          >
                                {images.map((item,index) => (
                               <SortableItem key={item.order}>
                                 <div className="item_img"
                                 style={{
                                  marginRight: 20,
                                  marginBottom: 20,
                                  width: '200px',
                                  borderRadius: 5,
                                  background:"url("+ item.src + ")",
                                  boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                                    }}>

                                 </div>
                                
                            </SortableItem>
                              ))}
                            </SortableList>

                        </div>
                        <div className='col-12 col-md-12'>
                          <CustomFileInput
                            label={t('MyEventPage.MainEventImage')}
                            id='poster'
                            handleFile={e => {
                              setFormData(state => ({
                                ...state,
                                poster: e.target.files[0]
                              }));
                             
                            }
                            }
                            info={t('MyEventPage.MainEventImageWarning')}
                            filename={poster ? poster.name : ''}
                          />
                                             <small className='form-text text-muted mb-3' style={{marginTop:'-20px'}}>
                             <FontAwesomeIcon
                               icon={faInfoCircle}
                               className='text-primary mr-1'
                              />
                            {t('maxlimit')}
                            </small>
                        </div>
                        <div className='col-12 col-md-12'>
                          <CustomSelect
                            className='custom_select-div'
                            label={t('MyEventPage.Audience')}
                            options={[
                              { label: 'Adults', value: 'Adults' },
                              { label: 'Children', value: 'Children' },
                              { label: 'Family', value: 'Family' },
                              { label: 'Group', value: 'Group' },
                              { label: 'Youth', value: 'Youth' }
                            ]}
                            name='audience'
                            value={audience}
                            isMulti
                            handleChange={e => {
                              setFormData(state => ({
                                ...state,
                                audience: e
                              }));
                            }}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <CustomSelect
                            className='custom_select-div'
                            label={t('MyEventPage.PaymentMethods')}
                            options={[
                              { label: 'Cash', value: 'Cash' },
                              { label: 'Bancontact', value: 'Bancontact' },
                              { label: 'Visa / Master', value: 'Visa / Master' },
                            ]}
                            name='payment_methods'
                            value={payment_methods}
                            isMulti
                            handleChange={e => {
                              setFormData(state => ({
                                ...state,
                                payment_methods: e
                              }));
                            }}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <LabeledInput
                            label={t('MyEventPage.YoutubeVideoURL')}
                            type='url'
                            id='video_link'
                            name='video_link'
                            value={video_link == null ? '' : video_link == 'null' ? '' : video_link}
                            onChange={onChange}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <LabeledInput
                            label={t('MyEventPage.redirecturl')}
                            type='url'
                            id='redirect_url'
                            name='redirect_url'
                            value={redirect_url == null ? '' : redirect_url == 'null' ? '' : redirect_url}
                            onChange={onChange}
                          />
                        </div>
                       
                      </div>

                      <div className='row'>
                        <div className='col-12 col-md-12'>
                          <h5>
                            {t('MyEventPage.TicketInfo')}
                          </h5>
                        </div>

                        <div className='col-12 col-md-12'>
                          <label htmlFor='Description'>
                            {t('MyEventPage.TermsAndCondition')}
                          </label>
                          <CKEditor
                            editor={ClassicEditor}
                            config={{
                              height: 300,
                              removePlugins: ['MediaEmbed'],
                              extraPlugins: [uploadPlugin]
                            }}
                            data={tickets_term_cond ? tickets_term_cond : ''}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setFormData(state => ({
                                ...state,
                                tickets_term_cond: data
                              }));
                            }}
                          />
                          </div>
                          {/* <LabeledInput
                            label='Terms and Condition'
                            type='text'
                            id='tickets_term_cond'
                            name='tickets_term_cond'
                            value={tickets_term_cond}
                            onChange={onChange}
                            required={true}
                          /> */}
                          <div className='col-12 col-md-12' style={{marginTop:'20px'}}>
                          <LabeledInput
                            label={t('MyEventPage.TicketFooter')}
                            type='text'
                            id='ticket_footer'
                            name='ticket_footer'
                            value={ticket_footer}
                            onChange={onChange}
                            
                            //required={true}
                          />

                        </div>
                      
                 <div className='col-12 col-md-12'>
                 <input
                  type='checkbox'
                  id='apply_fees'
                  name='apply_fees'
                  checked={apply_fees}
                  disabled
                  onChange={e =>
                    setFormData(state => ({
                      ...state,
                      apply_fees: e.target.checked ? 1 : 0
                    }))
                  }
                  placeholder='apply_fees'
                  style={{ marginRight: 5 }}
                />
                <label htmlFor='apply_fees'>{t('MyEventPage.apply_fees')}</label>
              </div>
              <div className='col-12 col-md-12'>
                 <input
                  type='checkbox'
                  id='daily_sale'
                  name='daily_sale'
                  checked={daily_sale}
                  onChange={e =>
                    setFormData(state => ({
                      ...state,
                      daily_sale: e.target.checked ? 1 : 0
                    }))
                  }
                  placeholder='daily_sale'
                  style={{ marginRight: 5 }}
                />
                <label htmlFor='daily_sale'>{t('MyEventPage.daily_sale')}</label>
              </div>
              <div className='col-12 col-md-12'>
                 <input
                  type='checkbox'
                  id='custom_field_enabled'
                  name='custom_field_enabled'
                  checked={custom_field_enabled}
                  onChange={e =>
                    setFormData(state => ({
                      ...state,
                      custom_field_enabled: e.target.checked ? 1 : 0
                    }))
                  }
                  placeholder='custom_field_enabled'
                  style={{ marginRight: 5 }}
                />
                <label htmlFor='custom_field_enabled'>{t('MyEventPage.custom_field')}</label>
              </div>
             
             
             {custom_field_enabled==1 && (
               <>
              
                <div className='col-12 col-md-12'>
                <LabeledInput
                            label={t('MyEventPage.CustomFieldLabel')}
                            type='text'
                            id='custom_field_label'
                            name='custom_field_label'
                            value={custom_field_label}
                            onChange={onChange}
                            required={true}
                          />
                </div>
                <div className='col-12 col-md-12'>
                          <RadioInput2
                            label={t('MyEventPage.CustomFieldRequired')}
                            info=''
                            entries={[
                              {
                                label: t('MyEventPage.Enable'),
                                value: '1',
                                id: 'enable_customfieldrequired',
                                name: 'custom_field_required',
                                selected: custom_field_required === 1 ? true : false
                              },
                              {
                                label:  t('MyEventPage.Disable'),
                                value: '0',
                                id: 'disable_customfieldrequired',
                                name: 'custom_field_required',
                                selected: custom_field_required === 0 ? true : false
                              }
                            ]}
                            onChange={onChange}
                          />
                        </div>
               
               
               </>
             )}
             <div className='col-12 col-md-12'>
                <RadioInput2
                  label={t('AllowIframe')}
                  info=''
                  entries={[
                    {
                      label: t('MyEventPage.Enable'),
                      value: '1',
                      id: 'enable_allow_iframe',
                      name: 'allow_iframe',
                      selected: allow_iframe === 1 ? true : false
                    },
                    {
                      label:  t('MyEventPage.Disable'),
                      value: '0',
                      id: 'disable_allow_iframe',
                      name: 'allow_iframe',
                      selected: allow_iframe === 0 ? true : false
                    }
                  ]}
                  onChange={onChange}
                />
              </div>
              {allow_iframe==1 && (
               <>
                <div className='col-12 col-md-12'>
                <LabeledInput
                            label={t('FrameText')}
                            type='text'
                            id='frame_text'
                            name='frame_text'
                            value={frame_text}
                            onChange={onChange}
                            required={true}
                          />
                </div>
                <div className='col-12 col-md-12'>
                <LabeledInput
                            label={t('FrameSubtext')}
                            type='text'
                            id='frame_subtext'
                            name='frame_subtext'
                            value={frame_subtext}
                            onChange={onChange}
                            required={true}
                          />
                </div>
               
                <div className='col-12 col-md-12'>
                <label htmlFor='button_color'>{t('buttoncolor')}</label>
                  <ColorPicker name='button_color' defaultColor={button_color} onChange={(color)=>{setFormData(state => ({ ...state, button_color: color }))}}/>
                </div>
                <div className='col-12 col-md-12' style={{marginTop:'15px'}}>
                <label htmlFor='url_color'>{t('urlcolor')}</label>
                <ColorPicker name='url_color' defaultColor={url_color} onChange={(color)=>{setFormData(state => ({ ...state, url_color: color }))}}/>
                </div>
               </>
             )}
              <div className='col-12 col-md-12' style={{marginTop:'10px'}}>
                 <input
                  type='checkbox'
                  id='password_protected'
                  name='password_protected'
                  checked={password_protected}
                  onChange={e =>
                    setFormData(state => ({
                      ...state,
                      password_protected: e.target.checked ? 1 : 0
                    }))
                  }
                  placeholder='password_protected'
                  style={{ marginRight: 5 }}
                />
                <label htmlFor='password_protected'>{t('MyEventPage.password_protected')}</label>
              </div>
                
              
                        <div className='col-12 col-md-12'>
                          <RadioInput2
                            label={t('MyEventPage.ShowTicketsLimit')}
                            info=''
                            entries={[
                              {
                                label: t('MyEventPage.Enable'),
                                value: '1',
                                id: 'enable_ticketLimit',
                                name: 'show_ticket_limit',
                                selected: show_ticket_limit === 1 ? true : false
                              },
                              {
                                label:  t('MyEventPage.Disable'),
                                value: '0',
                                id: 'disable_ticketLimit',
                                name: 'show_ticket_limit',
                                selected: show_ticket_limit === 0 ? true : false
                              }
                            ]}
                            onChange={onChange}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <RadioInput2
                            label={t('EventPage.ShowTicketsLimitPurchased')}
                            info=''
                            entries={[
                              {
                                label: t('MyEventPage.Enable'),
                                value: '1',
                                id: 'enable_ticketLimitPurchase',
                                name: 'show_ticket_limit_purchased',
                                selected: show_ticket_limit_purchased === 1 ? true : false
                              },
                              {
                                label:  t('MyEventPage.Disable'),
                                value: '0',
                                id: 'disable_ticketLimitPurchase',
                                name: 'show_ticket_limit_purchased',
                                selected: show_ticket_limit_purchased === 0 ? true : false
                              }
                            ]}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                     

                      {submitLoading ? (
                        <CustomLoader />
                      ) : (
                        <>
                          <button
                            type='submit'
                            name='_submit'
                            className='btn btn-primary btn'>
                            {t('MyEventPage.SaveButtonText')}
                          </button>

                          <button
                            onClick={DraftsubmitHandler}
                            type='submit'
                            name='_submit'
                            style={{marginLeft:'10px'}}
                            className='btn btn-primary btn'>
                            {t('MyEventPage.DraftButtonText')}
                          </button>
                        </>
                      )}
                    </>
                  )}
                </Card>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
