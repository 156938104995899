import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faSignInAlt,
  faUserPlus,
  faUser,
  faCalendar,
  faHome,
  faStream,
  faFolderOpen,
  faQuestionCircle,
  faNewspaper,
  faTicketAlt,
  faCalendarPlus
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import CategoryMenu from './CategoryMenu';
import { SIGNIN } from './../../service/actions/Index';
import CartIcon from './CartIcon';
import { SETTINGS_API } from '../../APIConfig';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import $ from 'jquery';
function getFaviconEl() {
  return document.getElementById("favicon");
}

function Header(props) {
  const { t, i18n } = useTranslation('common');
  const [forSignUp, setSignUp] = useState(false);
  const [exploreOpen, setExploreOpen] = useState(false);
  const [mobmenu, setmobmenu] = useState(false);
  const [UserInfo, setUserInfo] = useState([]);
  const [SettingData, setSettingData] = useState([]);
  const [search, setSearch] = useState();
  const dispatch = useDispatch();
  const [Adminuser, SetAdminUser] = useState(0);
  const history = useHistory();

  const cartItems = useSelector(state => state.cart.cartItems);

  const signUpHandler = e => {
    e.preventDefault();
    setSignUp(!forSignUp);
  };

  const exploreHandler = e => {
    e.preventDefault();
    setExploreOpen(!exploreOpen);
  };

  const MobMenuHndlr = e => {
    e.preventDefault();
    //  const height = document.getElementById('container').clientHeight;
    setmobmenu(!mobmenu);
  };
  const hello = () => {
    dispatch(SIGNIN({ test: 'test' }));
  };
  useEffect(() => {
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setUserInfo(foundUser);
    }
    $(".navbar a").click((ev) => {
      //setmobmenu(!mobmenu);
      // Update React state
      setmobmenu((prevMobmenu) => !prevMobmenu);
      //setmobmenu(!mobmenu);
    });

    // Cleanup the event handler when the component unmounts
    return () => {
      $(".navbar a").off('click');
    };
  }, []);

  // useState(() => , []);

  useEffect(() => getSettingData(), []);
  const getSettingData = async () => {
    try {
      await axios({
        url: SETTINGS_API,
        method: 'GET'
      }).then(function (res) {
        setSettingData(res.data.data);
      });
    } catch { }
  };

  const searchHandler = e => {
    history.push({
      pathname: '/event-list/All',
      state: { search: e.target.value }
    });
  };

  const IsLoggedIn = false;
  let sitelogo = SettingData.find(o => o.key === 'site.logo');
  let site_favicon = SettingData.find(o => o.key === 'site.site_favicon');
  React.useEffect(() => {
    const favicon = getFaviconEl();
    favicon.href = site_favicon === null || site_favicon === undefined ? '' : site_favicon.value;
  })
  React.useEffect(() => {
    const loggedInUser = localStorage.getItem('user');
    const IsAdminuser = localStorage.getItem('IsAdminuser');
    if (IsAdminuser == 'true') {
      SetAdminUser(1)
    }
  })
  return (
    <div>
      {Adminuser === 1 ?
        <div style={{ textAlign: 'center', backgroundColor: 'black', color: 'white', padding: '2px' }}>{t('LoggedInAs')} <strong>{UserInfo.user_detail.name}</strong>.
          <Link to='/adminsignout'>
          {t('ClickHere')}
          </Link> {t('BackToAdmin')}
        </div>
        :
        ''
      }
      <section className='header-main sticky-top'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-5 col-lg-3 order-0 order-lg-0 header-logo-wrapper'>
              <div className='brand-wrap'>
                <Link to='/'>
                  <img
                    className='logo img-fluid'
                    src={sitelogo == undefined ? '' : sitelogo.value}
                    alt='Ticketshop'
                  />
                </Link>
              </div>
            </div>
            <div className='col-12 col-lg-5 order-2 order-lg-1 mt-3 mb-3 mt-lg-0 mb-lg-0 header-search-wrapper'>
              <div className='search-wrap'>
                <div className='input-icon'>
                  <i>
                    <FontAwesomeIcon icon={faSearch} />
                  </i>
                  <input
                    name='keyword'
                    className='form-control top-search'
                    placeholder={t('Header.SearchForEvent')}
                    type='text'
                    value={search}
                    autoFocus={false}
                    autoComplete='false'
                    onChange={searchHandler}
                  />
                </div>
              </div>
            </div>
            <div style={{marginTop:'5px'}} className='col-7 col-lg-4 text-right order-1 order-lg-2 header-actions-wrapper'>
              {UserInfo.length == 0 ? (
                <div className='widgets-wrap d-flex justify-content-end align-items-center'>
                  <CartIcon />
                  <div className='widget-header'>
                    <Link to='/signin'>
                      <div className='icon-wrap icon-xs bg-primary round text-secondary d-lg-none d-xl-none'>
                        <FontAwesomeIcon
                          icon={faSignInAlt}
                          className='text-light fa-sm fa-fw'
                        />
                      </div>
                      <span className='d-none d-lg-block'>
                        {IsLoggedIn == true ? '' : t('Header.SignIn')}
                      </span>
                    </Link>
                  </div>

                  <div
                    className={
                      forSignUp ? 'widget-header open' : 'widget-header'
                    }>
                    <span
                      className='dropdown-toggle sign_up-pdng'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                      onMouseEnter={signUpHandler}
                      onMouseLeave={signUpHandler}
                      style={{ cursor: 'pointer' }}>
                      <div className='icon-wrap icon-xs bg-primary round text-secondary d-lg-none d-xl-none'>
                        <FontAwesomeIcon
                          icon={faUserPlus}
                          className='text-light fa-sm fa-fw'
                        />
                      </div>
                      <span className='d-none d-lg-inline'>{t('Header.SignUp')}</span>
                      <div
                        className={
                          forSignUp
                            ? 'dropdown-menu dropdown-menu-right dropdown-menu-arrow show'
                            : 'dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                        }>
                        <Link
                          to='/signup/attendee'
                          className='dropdown-item'
                          onClick={e => hello()}>
                          <FontAwesomeIcon icon={faUser} className='fa-fw' />
                          {t('Header.Attendee')}
                        </Link>
                        <Link to='/signup/organizer' className='dropdown-item'>
                          <FontAwesomeIcon
                            icon={faCalendar}
                            className='fa-fw'
                          />
                          {t('Header.Organizer')}
                        </Link>
                      </div>
                    </span>
                  </div>

                  <div className='widget-header d-lg-none'>
                    <button
                      className={`navbar-toggler ${mobmenu ? '' : 'collapsed'}`}
                      type='button'
                      data-toggle='collapse'
                      data-target='#main_nav'
                      aria-expanded='false'
                      aria-label='Toggle navigation'
                      onClick={MobMenuHndlr}>
                      <span className='icon-bar top-bar'></span>
                      <span className='icon-bar middle-bar'></span>
                      <span className='icon-bar bottom-bar'></span>
                    </button>
                  </div>
                </div>
              ) : (
                <div className='widgets-wrap d-flex justify-content-end align-items-center'>
                  <CartIcon />
                  <div
                    className={
                      forSignUp ? 'widget-header open' : 'widget-header'
                    }>
                    <span
                      className='dropdown-toggle sign_up-pdng'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                      onMouseEnter={signUpHandler}
                      onMouseLeave={signUpHandler}
                      style={{ cursor: 'pointer' }}>
                      <div className='icon-wrap icon-xs bg-primary round text-secondary d-lg-none d-xl-none'>
                        <FontAwesomeIcon
                          icon={faUserPlus}
                          className='text-light fa-sm fa-fw'
                        />
                      </div>
                      <span className='d-none d-lg-inline'>
                        <FontAwesomeIcon icon={faUser} className='fa-fw' />{' '}
                        {UserInfo.user_detail.name}
                      </span>
                      <div
                        className={
                          forSignUp
                            ? 'dropdown-menu dropdown-menu-right dropdown-menu-arrow show'
                            : 'dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                        }>
                        <Link
                          to={
                            UserInfo.user_detail.role_id === 2
                              ? '/dashboard/attendee/my-tickets'
                              : '/dashboard/organizer'
                          }
                          className='dropdown-item'
                          onClick={e => e.stopPropagation()}>
                          <FontAwesomeIcon icon={faUser} className='fa-fw' />
                          {t('Header.Dashboard')}
                        </Link>
                        <Link to='/signout' className='dropdown-item'>
                          <FontAwesomeIcon
                            icon={faCalendar}
                            className='fa-fw'
                          />
                          {t('Header.LogOut')}
                        </Link>
                      </div>
                    </span>
                  </div>

                  <div className='widget-header d-lg-none'>
                    <button
                      className={`navbar-toggler ${mobmenu ? '' : 'collapsed'}`}
                      type='button'
                      data-toggle='collapse'
                      data-target='#main_nav'
                      aria-expanded='false'
                      aria-label='Toggle navigation'
                      onClick={MobMenuHndlr}>
                      <span className='icon-bar top-bar'></span>
                      <span className='icon-bar middle-bar'></span>
                      <span className='icon-bar bottom-bar'></span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <header className='section-header'>
        <nav className='navbar navbar-expand-lg navbar-light bg-white border-0'>
          <div className='container'>
            <div
              className={`collapse navbar-collapse ${mobmenu ? 'show' : ''}`}
              id='main_nav'>
              <ul className='navbar-nav nav-fill w-100'>
                <li className='nav-item'>
                  <Link
                    to={`/`}
                    className={
                      props.active === 'dashboard'
                        ? 'nav-link active'
                        : 'nav-link'
                    }>
                    <FontAwesomeIcon icon={faHome} className='fa-fw' />
                    <span className="notranslate">Home</span>
                  </Link>
                </li>

                <li className='nav-item'>
                  <Link
                    to={`/event-list/All`}
                    className={
                      props.active === 'dashboard'
                        ? 'nav-link active'
                        : 'nav-link'
                    }>
                    <FontAwesomeIcon icon={faCalendar} className='fa-fw' />
                    {t('Header.BrowseEvents')}
                  </Link>
                </li>

                <li className='nav-item dropdown dropdown-hover'>
                  <span
                    className='nav-link dropdown-toggle custom_dropdown_new'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                    onMouseEnter={exploreHandler}
                    onMouseLeave={exploreHandler}
                    style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faStream} className='fa-fw' />
                    {t('Header.Explore')}
                    <div
                      className={
                        exploreOpen
                          ? 'dropdown-menu dropdown-menu-arrow m-0 show'
                          : 'dropdown-menu dropdown-menu-arrow m-0'
                      }>
                      <CategoryMenu BindCategory={props} />
                      <Link
                        to={`/event-list/All`}
                        className={
                          props.active === 'dashboard'
                            ? 'dropdown-item active'
                            : 'dropdown-item'
                        }>
                        <FontAwesomeIcon
                          icon={faFolderOpen}
                          className='fa-fw'
                        />
                        {t('Header.AllCategories')}
                      </Link>
                    </div>
                  </span>
                </li>

                <li className='nav-item'>
                  <Link
                    to={`/HowItWorks`}
                    className={
                      props.active === 'dashboard'
                        ? 'nav-link active'
                        : 'nav-link'
                    }>
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      className='fa-fw'
                    />
                    {t('Header.HowItWorks')}
                  </Link>
                </li>

                <li className='nav-item'>
                  <Link
                    to={`/blog`}
                    className={
                      props.active === 'dashboard'
                        ? 'nav-link active'
                        : 'nav-link'
                    }>
                    <FontAwesomeIcon icon={faNewspaper} className='fa-fw' />
                    {t('Header.Blogs')}
                  </Link>
                </li>
                {UserInfo && UserInfo.user_detail && (
                  <li className='nav-item'>
                    <Link
                      to={`/dashboard/attendee/my-tickets`}
                      className={
                        props.active === 'dashboard'
                          ? 'nav-link active'
                          : 'nav-link'
                      }>
                      <FontAwesomeIcon icon={faTicketAlt} className='fa-fw' />
                      {t('Header.MyTickets')}
                    </Link>
                  </li>
                )}
                {UserInfo &&
                  UserInfo.user_detail &&
                  UserInfo.user_detail.role_id === 3 && (
                    <li className='nav-item'>
                      <Link
                        to={`/dashboard/organizer/my-events/add`}
                        className={
                          props.active === 'dashboard'
                            ? 'nav-link active'
                            : 'nav-link'
                        }>
                        <FontAwesomeIcon
                          icon={faCalendarPlus}
                          className='fa-fw'
                        />
                        {t('Header.AddMyEvent')}
                      </Link>
                    </li>
                  )}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Header;
